import { instance } from "./base.service";

export const addReceiptService = (data) => {
  return instance.post("receipt/", data);
};

export const listReceiptService = (id) => {
  return instance.get(`receipt/company/${id}/`);
};

export const deleteReceiptService = (id) => {
  return instance.delete(`receipt/${id}/`);
};

