import { useFormik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import CustomModal from '../Modal'
import { inventoryListService } from '../../services/inventory.service'
import { CompanyContext } from '../../context/CompanyContext'
import { productsListService } from '../../services/products.service'

const closeIcon = require('../../assets/close_icon.png')

const ProductSelectSchema = Yup.object().shape({
  quantity: Yup.number().required('Required').min(1),
  selling_price: Yup.number().required('Required').min(0),
  product: Yup.number().required('Required'),
})

export default function SelectManualProduct({
  open,
  onClose,
  title,
  onAdd,
  initialValue,
}) {
  const { company } = useContext(CompanyContext)
  const [productList, setProductList] = useState([])
  const [inventoryList, setInventoryList] = useState([])

  const formik = useFormik({
    initialValues: {
      quantity: initialValue.quantity,
      selling_price: initialValue.price,
      product: initialValue.product,
    },
    enableReinitialize: true,
    validationSchema: ProductSelectSchema,
    onSubmit: (values) => {
      onAdd(values)
    },
  })

  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    fetchProducts()
  }, [])

  async function fetchProducts() {
    const invtResponse = await inventoryListService(company?.id)
    const prodResponse = await productsListService(company?.id)

    setInventoryList(invtResponse.data || [])
    setProductList(prodResponse.data || [])
  }

  const prod_id_name_mapping = {}
  for (let i = 0; i < productList.length; i++) {
    prod_id_name_mapping[productList[i].name] = productList[i].id
  }

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <label htmlFor="product">Inventory Product</label>
          <select
            id="product"
            name="product"
            value={formik.values.product}
            onChange={(e) => {
              const find = inventoryList?.find(
                (item) => +item.product === +e.target.value
              )
              formik.setFieldValue('selling_price', find.selling_price)
              formik.handleChange(e)
            }}
            className="border mb-2 p-2"
          >
            {inventoryList
              ?.filter((x) => x.quantity > 0)
              .map((product) => (
                <option value={product.product}>{product.product_name}</option>
                //<option value={prod_id_name_mapping[product.product_name]}>{product.product_name}</option>
              ))}
          </select>
          {formik.errors.product && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.product}
            </div>
          )}

          <label htmlFor="name">Quantity</label>
          <input
            id="quantity"
            name="quantity"
            type="number"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.quantity}
          />
          {formik.errors.quantity && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.quantity}
            </div>
          )}
          <label htmlFor="name">Price</label>
          {console.log('seling_price', formik.value)}
          <input
            id="selling_price"
            name="selling_price"
            className="border mb-2 p-2"
            onChange={(e) =>
              formik.setFieldValue(
                'selling_price',
                e.target.value
                  .split(
                    company.currency_symbol || company.currency_symbol_code
                  )[1]
                  .trim()
              )
            }
            value={`${
              company.currency_symbol || company.currency_symbol_code
            } ${formik.values.selling_price}`}
          />
          {formik.errors.selling_price && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.selling_price}
            </div>
          )}

          <div className="space-y-3">
            <button
              type="submit"
              className="btn-primary disabled:bg-gray-400"
              disabled={!(formik.isValid && formik.dirty)}
            >
              Submit
            </button>
            <button onClick={onClose} className="btn-primary">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  )
}
