import React, { useContext } from 'react'
import moment from 'moment'

import Table from '../../Table'
import { CompanyContext } from '../../../context/CompanyContext'

function IncomeTable({ data, onEdit, onDelete, productList }) {
  const { company } = useContext(CompanyContext)
  const columns = React.useMemo(
    () => [
      {
        Header: 'Total Income',
        accessor: 'total_income',
        Cell: (val) =>
          `${company.currency_symbol || company.currency_symbol_code} ${
            val.value
          }`,
      },
      {
        Header: 'Date',
        accessor: 'updated_at',
        Cell: ({ value }) => moment(value).format('YYYY-MM-DD'),
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      onEdit={onEdit}
      onDelete={onDelete}
      showDelete={false}
      dontShowButtons
      showEdit={false}
    />
  )
}

export default IncomeTable
