import React, { useContext } from 'react'

import Table from '../../Table'
import { CompanyContext } from '../../../context/CompanyContext'

function ExpenseTable({ data, onEdit, onDelete }) {
  const { company } = useContext(CompanyContext)
  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        accessor: '',
        width: 1,
        Cell: (row) => {
          console.log(row)
          return <div>{parseInt(row.row.id) + 1}</div>
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: (val) =>
          `${company.currency_symbol || company.currency_symbol_code} ${
            val.value
          }`,
      },
      {
        Header: 'Action',
        accessor: '',
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      onEdit={onEdit}
      onDelete={onDelete}
      showEdit={false}
    />
  )
}

export default ExpenseTable
