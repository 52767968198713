/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import DaySaleTable from './DaySale';
import ProductSaleTable from './ProductSale';
import ButtonLoading from '../ButtonLoading';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { BASE_URL } from '../../services/base.service';

export default function SalesReport({ data, onSendEmail, loadingIndicator }) {
  const { total_sale = 0, products = [], sales = [], download_link } = data;

  const downloadFile = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <div className='overflow-y-auto h-[510px]'>
      <div className="mt-4 text-lg font-semibold text-primary">
        Total Sale Amount: <span className="text-black">{total_sale.toFixed(2)}</span>
      </div>
      <div className="my-2 font-semibold text-primary">Day Sales</div>
      <DaySaleTable data={sales} />
      <div className="my-2 font-semibold text-primary">Products Sales</div>
      <ProductSaleTable data={products} />
      <div className='flex items-center space-x-3 mt-4'>
        <button onClick={onSendEmail} className=" btn-primary flex max-w-max">
          Send Email
          {loadingIndicator && <ButtonLoading />}
        </button>
        <a rel="noreferrer" className="btn-primary max-w-max" target="_blank"
        download style={{ cursor: 'pointer' }}
        onClick={() => downloadFile(`${BASE_URL}/${download_link}`, 'sale_report')}>Download Report</a>
      </div>
    </div>
  );
}
