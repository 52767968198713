import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import Logo from '../assets/logo.png';

export default function Register() {
  const [showPasswordSuggestion, setShowPasswordSuggestion] = useState(true)
  const { values, handleChange, handleSubmit, handleBlur, errors, isSubmitting, touched, validateField   } =
    useFormikContext();

    useEffect(() => {
      validateField('password1')
      if(!errors.password1) {
        setShowPasswordSuggestion(false)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[handleChange])

  return (
    <section className="w-screen h-screen bg-bg">
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <a href="/">
              <img className="mx-auto h-24 w-auto" src={Logo} alt="Workflow" />
            </a>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm space-y-2">
              <div>
                <label htmlFor="username" className='text-gray-700 mb-2' >
                  Username
                </label>
                <input
                  id="username"
                  name="username"
                  type="username"
                  autoComplete="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="login-field"
                />
                {errors.username && (
                  <div className="text-xs mb-2 text-red-500">
                    {errors.username}
                  </div>
                )}
              </div>
              <div>
                <label htmlFor="name" className='text-gray-700 mb-2'>
                  name
                </label>
                <input
                  id="name"
                  name="name"
                  type="name"
                  autoComplete="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="login-field"
                />
                {errors.name && (
                  <div className="text-xs mb-2 text-red-500">{errors.name}</div>
                )}
              </div>
              <div>
                <label htmlFor="email-address" className='text-gray-700 mb-2'>
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="login-field"
                />
                {errors.email && (
                  <div className="text-xs mb-2 text-red-500">
                    {errors.email}
                  </div>
                )}
              </div>
              <div>
                <label htmlFor="password1" className='text-gray-700 mb-2'>
                  Password
                </label>
                <input
                  id="password1"
                  name="password1"
                  type="password"
                  value={values.password1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="password1"
                  className="login-field"
                />
                {showPasswordSuggestion && (
                   <span className='text-xs text-red-500 leading-3 '>*Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character</span>
                ) }
                
                {errors.password1 && (
                  <div className="text-xs mb-2 text-red-500 mt-3">
                  {errors.password1}
                </div>
                )}
              </div>

              <div>
                <label htmlFor="password2" className='text-gray-700 mb-2'>
                  Confirm Password
                </label>
                <input
                  id="password2"
                  name="password2"
                  type="password"
                  value={values.password2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="password2"
                  className="login-field"
                />
                {errors.password2 && (
                  <div className="text-xs mb-2 text-red-500">
                    {errors.password2}
                  </div>
                )}
              </div>
            </div>
            <div>
            <button
                type="submit"
                className="btn-primary"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                Register
                {isSubmitting && (
                  <svg class="animate-spin ml-4 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
