import React from 'react';

import Table from '../Table';

function CustomerTable({data, onEdit, onDelete}) {
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        width:1,
        Cell: (row) => {
          console.log(row)
            return <div>{parseInt(row.row.id) + 1}</div>;
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_number',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Action',
        accessor: 'progress',
      },
    ],
    []
  );


  return <Table columns={columns} data={data} onEdit={onEdit} onDelete={onDelete} />;
}

export default CustomerTable;
