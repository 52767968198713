import React from 'react'

export default function RegistrationRedirect() {
    
  React.useEffect(() => {
    const split_url = window.location.href.split('/')
    window.location.replace(`https://api.bokia.co.uk/registration/account-confirm-email/${split_url[split_url.length - 1 ]}/`)
  },[])

  return (
    <div></div>
  )
}
