import React from 'react';

import Table from '../Table';

function ProductSaleTable({ data }) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'product_name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
    ],
    []
  );

  return <Table columns={columns} data={data} dontShowButtons dontUseFixedHeight />;
}

export default ProductSaleTable;
