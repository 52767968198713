import { useContext, useEffect, useState } from "react";
import AddReceipt from "../components/bookkeeping/receipt/AddReceipt";
import { CompanyContext } from "../context/CompanyContext";
import SidebarLayout from "../layout/SidebarLayout";
import { customerListService } from "../services/customer.service";
import {
  addReceiptService,
  deleteReceiptService,
  listReceiptService,
} from "../services/receipt.service";
import { productsListService } from "../services/products.service";
import {inventoryListService} from "../services/inventory.service";
import Receipt from "../components/bookkeeping/receipt/Receipt";
import { LoadingContext } from "../context/LoadingContext";
import { errorToast } from "../utils/toasts";


export default function InvoicePage() {
  const { company } = useContext(CompanyContext);
  const { setLoading } = useContext(LoadingContext);
  const [addForm, setAddForm] = useState(false);
  const [productList, setProductList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [listReceipt, setListReceipt] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);


  useEffect(() => {
    fetchProducts();
    fetchCustomers();
    fetchReceipt();
    fetchInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchProducts() {
    try {
      setLoading(true);
      const res = await productsListService(company?.id);
      setProductList(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function fetchCustomers() {
    try {
      setLoading(true);
      const res = await customerListService(company?.id);
      setCustomerList(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function fetchInventory() {
    try {
      setLoading(true);
      const res = await inventoryListService(company?.id);
      setInventoryList(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  
  async function fetchReceipt() {
    try {
      setLoading(true);
      const res = await listReceiptService(company?.id);
      console.log('fetch receipt',res.data)
      const sorted = res.data.sort(function(a, b) {
        var keyA = new Date(a.updated_at),
          keyB = new Date(b.updated_at);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      setListReceipt(sorted);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onAddClick = () => {
    setAddForm(true);
  };

  const closeAddForm = () => {
    setAddForm(false);
  };

  const onAdd = async (data) => {
    try {
      setLoading(true);
      await addReceiptService(data);
      await fetchReceipt();
      closeAddForm();
      setLoading(false);
    } catch (error) {
      console.log('error',error)
      setLoading(false);
      errorToast(error?.data?.error)
    }
  };

  const onDelete = async ({ id }) => {
    try {
      setLoading(true);
      await deleteReceiptService(id);
      await fetchReceipt();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <SidebarLayout title="Receipts">
      <Receipt
        onAddClick={onAddClick}
        data={listReceipt}
        onEdit={() => {}}
        onDelete={onDelete}
      />
      <AddReceipt
        company={company}
        open={addForm}
        onClose={closeAddForm}
        title="Add New Receipt"
        onAdd={onAdd}
        productList={productList}
        inventoryList={inventoryList}
      />
    </SidebarLayout>
  );
}
