import Select, { components } from 'react-select'
import countriesJSON from '../countries.json'

const CustomOption = ({ children, ...props }) => {
  const { name, flag } = props.data
  return (
    <components.Option {...props} className="p-0">
      <div
        className="flex space-x-3 items-center
      my-2 cursor-pointer"
      >
        {name}
        <img
          className="ml-2"
          alt="flag"
          src={`data:image/png;base64, ${flag}`}
        />
      </div>
    </components.Option>
  )
}

export default function CountrySelect({ handleChange, defaultValue }) {
  return (
    <Select
      className="mb-4"
      closeMenuOnSelect
      options={countriesJSON}
      getOptionValue={(option) => option.name}
      onChange={(e) => {
        console.log('onchange', e)
        handleChange('currency_symbol', e.currency.code)
        handleChange('country', e.id)
      }}
      formatOptionLabel={(d) => {
        return (
          <div className="flex space-x-3 items-center">
            {d.name}
            <img
              className="ml-2"
              alt="flag"
              src={`data:image/png;base64, ${d.flag}`}
            />
          </div>
        )
      }}
      defaultValue={countriesJSON.find((c) => c.id === defaultValue)}
      components={{
        Option: CustomOption,
      }}
    />
  )
}
