/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import AddButton from '../components/AddButton'
import CustomerAddForm from '../components/customer/CustomerAddForm'
import CustomerTable from '../components/customer/CustomerTable'
import CustomerUpdateForm from '../components/customer/CustomerUpdateForm'
import DeleteConfirmation from '../components/DeleteConfirmation'
import { CompanyContext } from '../context/CompanyContext'
import { LoadingContext } from '../context/LoadingContext'
import StockAlert from '../components/StoctAlert'
import SidebarLayout from '../layout/SidebarLayout'
import {
  customerAddService,
  customerDeleteService,
  customerListService,
  customerUpdateService,
} from '../services/customer.service'

export default function CustomerPage() {
  const [data, setData] = useState([])

  const [addForm, showAddForm] = React.useState(false)
  const { company } = useContext(CompanyContext)
  const { setLoading } = useContext(LoadingContext)

  const [updateForm, showUpdateForm] = React.useState(false)
  const [updateFormData, setUpdateFormData] = React.useState({})
  const [deleteModel, showDeleteModal] = React.useState(false)
  const [currentDeleteId, setCurrentDeleteId] = React.useState(null)

  useEffect(() => {
    fetchCustomer()
  }, [])

  async function fetchCustomer() {
    try {
      setLoading(true)
      const res = await customerListService(company?.id)
      setData(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  function openDeleteModal(data) {
    try {
      setLoading(true)
      const { id } = data
      setCurrentDeleteId(id)
      showDeleteModal(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  function closeDeleteModal() {
    showDeleteModal(false)
  }

  function openForm() {
    showAddForm(true)
  }

  function closeForm() {
    showAddForm(false)
  }

  function openUpdateForm(data) {
    showUpdateForm(true)
    setUpdateFormData(data)
  }

  function closeUpdateForm() {
    showUpdateForm(false)
  }

  async function onCustomerAdd(data) {
    try {
      setLoading(true)
      await customerAddService(data)
      await fetchCustomer()
      closeForm()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function onCustomerDelete() {
    try {
      setLoading(true)
      await customerDeleteService(currentDeleteId)
      await fetchCustomer()
      closeDeleteModal()
      setCurrentDeleteId(null)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function onCustomerUpdate(data) {
    try {
      setLoading(true)
      const { id } = updateFormData
      await customerUpdateService(id, data)
      await fetchCustomer()
      setUpdateFormData({})
      closeUpdateForm()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  return (
    <SidebarLayout title="Customers">
      <AddButton onClick={openForm} title="Add Customer" />
      <CustomerTable
        data={data}
        onEdit={openUpdateForm}
        onDelete={openDeleteModal}
      />
      <CustomerAddForm
        company={company}
        open={addForm}
        onClose={closeForm}
        title="Add Customer"
        onAdd={onCustomerAdd}
      />
      <CustomerUpdateForm
        company={company}
        open={updateForm}
        data={updateFormData}
        onClose={closeUpdateForm}
        title="Update Customer"
        onUpdate={onCustomerUpdate}
      />
      <DeleteConfirmation
        open={deleteModel}
        onClose={closeDeleteModal}
        onDelete={onCustomerDelete}
      />
      <StockAlert />
    </SidebarLayout>
  )
}
