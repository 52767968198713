import CompanyCard from './CompanyCard';
import Logo from '../assets/logo.png';
import Footer from './Footer';
export default function CompaniesList({
  list,
  onBack,
  onDelete,
  onAdd,
  onEdit,
  onSelect,
}) {
  return (
    <div>
      <section className="w-screen h-screen bg-bg flex justify-center items-center ">
        <div className="flex flex-col w-full items-center">
          <div className="flex justify-center">
            <a href="/welcome" className="relative text-primary">
              <img src={Logo} alt="logo" className="w-24 h-24 object-contain" />
              <span className="absolute text-base text-center left-24 top-4 border border-primary rounded-full w-5 h-5">
                &trade;
              </span>
            </a>
          </div>
          <div className="bg-white shadow-sm rounded-sm p-4 w-full max-w-md">
            <span className="block font-bold text-md">Companies</span>
            <div className="py-4 mt-4 divide-y h-full max-h-96 overflow-y-auto">
              {list?.map((company) => (
                <CompanyCard
                  data={company}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onSelect={onSelect}
                />
              ))}
            </div>
            <button className="btn-primary mt-4" onClick={onAdd}>
              Add Company
            </button>
            <button className="btn-primary mt-4" onClick={onBack}>
              Back
            </button>
          </div>
        </div>
      </section>
      <div className="sm:absolute left-0 bottom-0 right-0 bg-white">
        <Footer />
      </div>
    </div>
  );
}
