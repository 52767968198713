import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import CustomModal from '../Modal';

const closeIcon = require('../../assets/close_icon.png')

const CustomerUpdateSchema = Yup.object().shape({
  name: Yup.string().required('Required').min(5).max(20),
  address: Yup.string().required('Required').min(10).max(50),
  phone_number: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  company: Yup.string().required('Required'),
});

export default function CustomerUpdateForm({ data, open, onClose, title, onUpdate, company }) {
  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      phone_number: '',
      email: '',
      company: company.id
    },
    validationSchema:CustomerUpdateSchema,
    onSubmit: (values, {resetForm}) => {
      onUpdate(values,resetForm)
    },

  });

  useEffect(() => {
    if(data) {
        const {setValues} = formik
        setValues(data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data,open]);

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
        <label htmlFor="name">Name</label>
          <input
            id="name"
            name="name"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          <label htmlFor="name">Address</label>
          <input
            id="address"
            name="address"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.address}
          />
           <label htmlFor="name">Phone Number</label>
          <input
            id="phone_number"
            name="phone_number"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.phone_number}
          />
           <label htmlFor="name">Email</label>
          <input
            id="email"
            name="email"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <label htmlFor="company">Company</label>
          <input
            className="border mb-2 p-2"
            value={company.name}
          />
          <div className="space-y-3">
            <button type="submit" className="btn-primary">
              Submit
            </button>
            <button onClick={onClose} className="btn-primary">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  );
}
