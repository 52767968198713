import {useEffect} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomModal from './Modal';

const closeIcon = require('../assets/close_icon.png')

const ProductSchemaSchema = Yup.object().shape({
  name: Yup.string().required('Required').max(200),
  description: Yup.string().required('Required').min(5).max(300),
  company: Yup.string().required('Required'),
});

export default function ProductAdd({ open, onClose, title, onAdd, company }) {
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      company: company.id
    },
    validationSchema:ProductSchemaSchema,
    onSubmit: (values) => {
      onAdd(values)
    },

  });

  useEffect(() => {
    if(open) { 
      formik.resetForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
      <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            name="name"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.errors.name && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.name}
            </div>
          )}
          <label htmlFor="description">Description</label>
          <input
            id="description"
            name="description"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
          {formik.errors.description && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.description}
            </div>
          )}
          <label htmlFor="company">Company</label>
          <input
            className="border mb-2 p-2"
            value={company.name}
          />
           <div className="space-y-3">
            <button type="submit" className="btn-primary disabled:bg-gray-400" disabled={!(formik.isValid && formik.dirty)}>
              Submit
            </button>
            <button onClick={onClose} className="btn-primary">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  );
}
