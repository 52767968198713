import { instance } from "./base.service";

export const loginService = (data) => {
  return instance.post("v1/auth/login/", data);
};

export const googleLoginService = (data) => {
  return instance.post("v1/auth/social-login/google/", data);
};

export const facebookLoginService = (data) => {
  return instance.post("v1/auth/social-login/facebook/", data);
};

export const registerService = (data) => {
  return instance.post("registration/", data);
};

export const changePasswordService = (data) => {
  return instance.post("v1/auth/password/change/", data);
};

export const forgotPasswordService = (data) => {
  return instance.post("v1/auth/password/reset/", data);
};

export const deleteUserService = () => {
  return instance.delete("v1/auth/user-delete/");
};

