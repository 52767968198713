import { Formik } from "formik";
import * as Yup from "yup";
import Forgot from "../components/Forgot";
import { forgotPasswordService } from "../services/auth.service";
import { errorToast, successToast } from "../utils/toasts";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

export default function ForgotPasswordPage() {
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      const { email } = values;
      await forgotPasswordService({
        email,
      });
      successToast("Password reset e-mail has been sent.");
      window.location.replace("/login");
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      errorToast("something went wrong");
    }
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={LoginSchema}
      onSubmit={onSubmit}
    >
      <Forgot />
    </Formik>
  );
}
