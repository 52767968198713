import { useFormik } from 'formik'
import { useState, useEffect, useContext } from 'react'
import * as Yup from 'yup'
import CustomModal from '../Modal'
import ProductAdd from '../ProductAdd'
import AddButton from '../AddButton'
import {
  productAddService,
  productsListService,
} from '../../services/products.service'

import { LoadingContext } from '../../context/LoadingContext'

const closeIcon = require('../../assets/close_icon.png')
const addIcon = require('../../assets/add_icon.png')

const InventoryAddSchema = Yup.object().shape({
  supplier: Yup.string().required('Required').min(5).max(300),
  quantity: Yup.number().required('Required').min(1),
  cost_price: Yup.number().required('Required').min(0),
  selling_price: Yup.number().required('Required').min(0),
  restock_level: Yup.number().required('Required').min(1),
  product: Yup.number().required('Required'),
  company: Yup.number().required('Required'),
})

export default function InventoryAddForm({
  open,
  onClose,
  title,
  onAdd,
  company,
  productList,
}) {
  const [addForm, showAddForm] = useState(false)
  const { setLoading } = useContext(LoadingContext)
  const [data, setData] = useState([])

  const formik = useFormik({
    initialValues: {
      supplier: 'Supplier name',
      quantity: 0,
      cost_price: 0,
      selling_price: 0,
      restock_level: 0,
      product: data.slice(-1)[0]?.id,
      product_name: data.slice(-1)[0]?.name,
      company: company.id,
    },
    // enableReinitialize: true,
    validationSchema: InventoryAddSchema,
    onSubmit: (values) => {
      console.log(values)
      onAdd(values)
    },
  })

  const openAddProduct = () => {
    showAddForm(true)
  }

  const closeFormProduct = () => {
    showAddForm(false)
  }

  useEffect(() => {
    setData(productList)
  }, [productList])

  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  async function onProductAdd(data) {
    try {
      setLoading(true)
      await productAddService(data)
      await fetchProducts()
      setLoading(false)
      closeFormProduct()
    } catch (error) {
      setLoading(false)
    }
  }

  async function fetchProducts() {
    try {
      setLoading(true)
      let res = await productsListService(company?.id)
      res = res.data?.sort(function (a, b) {
        var keyA = new Date(a.created_at),
          keyB = new Date(b.created_at)
        // Compare the 2 dates
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
      })
      console.log('res', res)
      formik.setFieldValue('product', res.slice(-1)[0]?.id)
      formik.setFieldValue('product_name', res.slice(-1)[0]?.id)
      setData(res)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <div className="flex space-x-2">
            <div className="w-full">
              <label htmlFor="product">Product</label>
              <select
                id="product"
                name="product"
                value={formik.values.product}
                onChange={(event) => {
                  formik.setFieldValue('product', event.target.value)
                  formik.setFieldValue(
                    'product_name',
                    data.find((p) => p.id === event.target.value)?.name
                  )
                }}
                className="border mb-2 p-2 w-full"
              >
                {data?.map((product) => (
                  <option value={product.id}>{product.name}</option>
                ))}
              </select>
              {formik.errors.product && (
                <div className="text-xs mb-2 text-red-500">
                  {formik.errors.product}
                </div>
              )}
            </div>
            <img
              src={addIcon}
              alt="add-icon"
              className="object-contain mt-3 cursor-pointer"
              onClick={openAddProduct}
            />
          </div>
          <label htmlFor="name">Supplier</label>
          <input
            id="supplier"
            name="supplier"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.supplier}
          />
          {formik.errors.supplier && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.supplier}
            </div>
          )}
          <label htmlFor="name">Quantity</label>
          <input
            id="quantity"
            name="quantity"
            type="number"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.quantity}
          />
          {formik.errors.quantity && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.quantity}
            </div>
          )}
          <label htmlFor="name">Cost Price</label>
          <input
            id="cost_price"
            name="cost_price"
            className="border mb-2 p-2"
            onChange={(e) =>
              formik.setFieldValue(
                'cost_price',
                e.target.value
                  .split(
                    company.currency_symbol || company.currency_symbol_code
                  )[1]
                  .trim()
              )
            }
            value={`${
              company.currency_symbol || company.currency_symbol_code
            } ${formik.values.cost_price}`}
          />
          {formik.errors.cost_price && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.cost_price}
            </div>
          )}
          <label htmlFor="name">Selling Price</label>
          <input
            id="selling_price"
            name="selling_price"
            className="border mb-2 p-2"
            onChange={(e) =>
              formik.setFieldValue(
                'selling_price',
                e.target.value
                  .split(
                    company.currency_symbol || company.currency_symbol_code
                  )[1]
                  .trim()
              )
            }
            value={`${
              company.currency_symbol || company.currency_symbol_code
            } ${formik.values.selling_price}`}
          />
          {formik.errors.selling_price && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.selling_price}
            </div>
          )}
          <label htmlFor="name">Restock Level</label>
          <input
            id="restock_level"
            name="restock_level"
            type="number"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.restock_level}
          />
          {formik.errors.restock_level && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.restock_level}
            </div>
          )}

          <label htmlFor="company">Company</label>
          <input className="border mb-2 p-2" value={company.name} />
          <div className="space-y-3">
            <button
              type="submit"
              className="btn-primary disabled:bg-gray-400"
              disabled={!(formik.isValid && formik.dirty)}
            >
              Submit
            </button>
            <button onClick={onClose} className="btn-primary">
              Cancel
            </button>
          </div>
        </form>
      </div>
      <ProductAdd
        company={company}
        open={addForm}
        onClose={closeFormProduct}
        title="Add New Product"
        onAdd={onProductAdd}
      />
    </CustomModal>
  )
}
