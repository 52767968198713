import { Formik } from "formik";
import * as Yup from "yup";
import Register from "../components/Register";
import { registerService } from "../services/auth.service";
import { successToast, warningToast } from "../utils/toasts";

const RegisterSchema = Yup.object().shape({
  username: Yup.string().required("Required").max(20).min(5),
  name: Yup.string().required("Required").max(25).min(5),
  email: Yup.string().email("Invalid email").required("Required"),
  password1: Yup.string()
  .required('*Must Contain 8 Characters, Which Includes Atleast One Uppercase Character')
  .matches(
    /^(?=.*?[A-Z]).{8,}$/,
    "*Must Contain 8 Characters, Which Includes Atleast One Uppercase Character."
  ),
  password2: Yup.string()
     .oneOf([Yup.ref('password1'), null], 'Passwords must match')
});

export default function RegisterPage() {
  // const onSubmit = async (values) => {
  //   const res = await loginService(values);
  //   localStorage.setItem("token", res.data.key);
  //   window.location.replace("/welcome");
  // };

  const onRegister = async (values, {setSubmitting, resetForm }) => {
    try {
    setSubmitting(true)
    await registerService(values)
    resetForm()
    successToast('Verification email has been sent successfully please check your inbox.');
    setTimeout(() => {
      setSubmitting(false)
      window.location.replace('/login');
    }, 1000);
    } catch (error) {
      setSubmitting(false)
      warningToast('something went wrong while registering')
    }

  }

  return (
    <Formik
      initialValues={{ email: "", password1: "", password2: "", username: "", name: "" }}
      validationSchema={RegisterSchema}
      onSubmit={onRegister}
    >
      <Register />
    </Formik>
  );
}
