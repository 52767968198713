import { useContext, useEffect, useState } from 'react'
import CustomModal from '../Modal'
import {
  productAddService,
  productsListService,
} from '../../services/products.service'
import { CompanyContext } from '../../context/CompanyContext'
import { successToast } from '../../utils/toasts'
import AddProductForm from './AddProjectModal'
import { inventoryAddService } from '../../services/inventory.service'
import { LoadingContext } from '../../context/LoadingContext'

const closeIcon = require('../../assets/close_icon.png')

export default function SelectProduct({ open, onClose, title, onSuccessScan }) {
  const { company } = useContext(CompanyContext)
  const { setLoading } = useContext(LoadingContext)

  const [barcodeData, setBarcodeData] = useState('')
  const [typingTimeout, setTypingTimeout] = useState(null)
  const [productList, setProductList] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [scannedBarcode, setScannedBarcode] = useState(0)

  useEffect(() => {
    fetchProducts()
  }, [])

  async function fetchProducts() {
    const response = await productsListService(company?.id)
    setProductList(response.data || [])
  }

  const handleInputChange = (e) => {
    setBarcodeData(e.target.value)

    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    setTypingTimeout(
      setTimeout(() => {
        processBarcodeData(e.target.value)
      }, 1000)
    )
  }

  const processBarcodeData = (data) => {
    console.log('Scanned data:', data)

    //fetch product
    const product = productList?.find((product) => product?.code === data)
    if (product) {
      // successToast('product added')
      onSuccessScan(product)
      setBarcodeData('')
      onClose()
    }
    if (!product) {
      setScannedBarcode(data)
      setShowMessage(true)
    }
    // Process the data as needed, such as saving it to state or sending it to an API
  }

  const handleOnAdd = async (values) => {
    const {
      name,
      description,
      supplier,
      quantity,
      cost_price,
      selling_price,
      restock_level,
    } = values

    setLoading(true)
    // create product
    const productResponse = await productAddService({
      name,
      description,
      company: company?.id,
      code: parseInt(scannedBarcode),
    })

    setProductList((prev) => [...prev, productResponse.data])

    // create inventory
    await inventoryAddService({
      supplier,
      quantity,
      cost_price,
      selling_price,
      restock_level,
      product: productResponse.data?.id,
      company: company?.id,
    })

    onSuccessScan(productResponse.data)
    setShowCreateForm(false)
    setShowMessage(false)
    onClose()
    setLoading(false)
    setBarcodeData('')
  }

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600"></h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={() => {
              setBarcodeData('')
              onClose()
            }}
          />
        </div>
        <div className="flex flex-col justify-center items-center space-y-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            className="w-6 h-6"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zm0 9.75c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zm9.75-9.75c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 6.75h.75v.75h-.75v-.75zm0 9.75h.75v.75h-.75v-.75zm9.75-9.75h.75v.75h-.75v-.75zm-3 6.75h.75v.75h-.75v-.75zm0 6h.75v.75h-.75v-.75zm6-6h.75v.75h-.75v-.75zm0 6h.75v.75h-.75v-.75zm-3-3h.75v.75h-.75v-.75z"
            ></path>
          </svg>

          <h2 className="font-bold">Scan Product</h2>
          <p>Please point your camera at the product's barcode to scan.</p>
          <input
            type="text"
            value={barcodeData}
            onChange={handleInputChange}
            autoFocus
            style={{ opacity: 0, position: 'absolute' }}
          />
        </div>
      </div>
      <CustomModal
        open={showMessage}
        onClose={() => {
          setBarcodeData('')
          setShowMessage(false)
        }}
        title={''}
      >
        <div className="w-[70vw] sm:w-[50vw]">
          <div className="flex justify-between items-center w-full">
            <h1 className="font-bold text-lg text-black-600"></h1>
            <img
              className="w-6 h-6 cursor-pointer"
              alt="close-btn"
              src={closeIcon}
              onClick={() => setShowMessage(false)}
            />
          </div>
          <div className="flex flex-col justify-center items-center space-y-2">
            <p>
              the scanned product doesn't exists in system please click Create
              to create the product
            </p>

            <div className="flex space-x-2">
              <div
                onClick={() => setShowMessage(false)}
                className="btn-primary bg-gray-400 hover:bg-gray-500 btn- max-w-max cursor-pointer"
              >
                Cancel
              </div>
              <div
                onClick={() => setShowCreateForm(true)}
                className="btn-primary max-w-max cursor-pointer"
              >
                Create
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <AddProductForm
        onAdd={handleOnAdd}
        onClose={() => setShowCreateForm(false)}
        open={showCreateForm}
        title={''}
        key={'product_create_form'}
      />
    </CustomModal>
  )
}
