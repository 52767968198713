import IncomeTable from "./IncomeTable";

export default function Income({ onAddClick, data, onEdit, onDelete }) {
  return (
    <div className="mt-10">
      <button onClick={onAddClick} className="btn-primary max-w-max">
        Add Income
      </button>
      <IncomeTable data={data} onDelete={onDelete} onEdit={onEdit} />
    </div>
  );
}
