import FeatureSidebar from '../../components/FeatureSidebar';
import Footer from '../../components/Footer';

export default function Customers() {
  return (
    <div>
      <div className="flex sm:h-[90vh] overflow-hidden bg-white rounded-lg">
        <div className="hidden md:flex md:flex-shrink-0">
          <FeatureSidebar />
        </div>
        <div className="flex flex-col flex-1 w-0 overflow-hidden">
          <main className="relative flex-1 focus:outline-none">
            <div className="py-6">
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 mb-4"></div>

              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8"></div>
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                <h1 className="text-lg text-black-600 font-bold">
                  {' '}
                  Customers{' '}
                </h1>

                <p>
                  This feature allows your company to add all the customers that
                  you supply your products to. This can be done simply by adding
                  the customer’s:
                </p>
                <ul className='ml-4 my-2 list-disc'>
                  <li>Name </li>
                  <li>Address </li>
                  <li>Phone number</li>
                  <li>Email</li>
                </ul>
                <p>
                  You can now easily keep track of your customers, seeing all
                  the customers in your business’ database.
                </p>
                <p>
                  All you need to do is create an account and start using this
                  feature today!
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
}
