/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from 'react'
import AddButton from '../components/AddButton'
import DeleteConfirmation from '../components/DeleteConfirmation'
import InventoryAddForm from '../components/inventory/InventoryAddForm'
import InventoryTable from '../components/inventory/InventoryTable'
import InventoryUpdateForm from '../components/inventory/InventoryUpdateForm'
import StockAlert from '../components/StoctAlert'
import { CompanyContext } from '../context/CompanyContext'
import { LoadingContext } from '../context/LoadingContext'
import SidebarLayout from '../layout/SidebarLayout'
import {
  inventoryAddService,
  inventoryDeleteService,
  inventoryListService,
  inventoryUpdateService,
  inventory_bulk_upload,
} from '../services/inventory.service'
import {
  productFetchService,
  productsListService,
} from '../services/products.service'
import { toast } from 'react-toastify'
import { errorToast, successToast } from '../utils/toasts'
import ScanWithCamera from '../components/checkout/ScanWithCamera'
import ScanProduct from '../components/checkout/ScanProduct'
import SelectManualProduct from '../components/checkout/SelectManualProduct'

export default function InventoryPage() {
  const [data, setData] = useState([])
  const inputRef = useRef()
  const [productList, setProductList] = React.useState([])
  const [addForm, showAddForm] = React.useState(false)
  const [selectForm, setSelectForm] = React.useState(false)
  const [manualForm, setManualForm] = React.useState(false)
  const { company } = useContext(CompanyContext)
  const { setLoading } = useContext(LoadingContext)
  const [updateForm, showUpdateForm] = React.useState(false)
  const [updateFormData, setUpdateFormData] = React.useState({})
  const [deleteModel, showDeleteModal] = React.useState(false)
  const [currentDeleteId, setCurrentDeleteId] = React.useState(null)
  const [scanWithCamera, setScanWithCamera] = React.useState(false)

  const [initialValue, setInitialValue] = useState({
    quantity: 0,
    price: data[0]?.selling_price,
    product: (data?.filter((x) => x.quantity > 0))[0]?.product,
  })

  useEffect(() => {
    fetchInventory()
    fetchProducts()
  }, [])

  const onTableRowClick = (item) => {
    setInitialValue({
      quantity: item.quantity,
      price: item.selling_price,
      product: item.product,
    })
    setManualForm(true)
  }

  async function fetchInventory() {
    try {
      setLoading(true)
      const res = await inventoryListService(company?.id)
      setData(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function fetchProducts() {
    try {
      setLoading(true)
      const res = await productsListService(company?.id)
      setProductList(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  function openDeleteModal(data) {
    try {
      setLoading(true)
      const { id } = data
      setCurrentDeleteId(id)
      showDeleteModal(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  function closeDeleteModal() {
    showDeleteModal(false)
  }

  function openForm() {
    showAddForm(true)
  }

  function closeForm() {
    showAddForm(false)
  }

  function openUpdateForm(data) {
    showUpdateForm(true)
    setUpdateFormData(data)
  }

  function closeUpdateForm() {
    showUpdateForm(false)
  }

  const handleUploadButton = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  async function onInventoryAdd(data) {
    try {
      setLoading(true)
      await inventoryAddService({ ...data, product: +data.product })
      await fetchInventory()
      closeForm()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      errorToast(error?.data?.error)
    }
  }

  async function onInventoryDelete() {
    try {
      setLoading(true)
      await inventoryDeleteService(currentDeleteId)
      await fetchInventory()
      setCurrentDeleteId(null)
      setLoading(false)
      closeDeleteModal()
    } catch (error) {
      setLoading(false)
      errorToast(error?.data?.error)
    }
  }

  async function onInventoryUpdate(data) {
    try {
      setLoading(true)
      const { id } = updateFormData
      await inventoryUpdateService(id, data)
      await fetchInventory()
      setUpdateFormData({})
      closeUpdateForm()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      errorToast(error?.data?.error)
    }
  }

  const onFileClick = (event) => {
    const element = event.target
    element.value = ''
  }

  const onFileChange = async (event) => {
    if (event.target.files[0]) {
      const targetFile = event.target.files[0]
      const formData = new FormData()

      // refactor add name
      formData.append('csv_file', targetFile)
      formData.append('company', company?.id)

      // upload formdata to backend

      try {
        setLoading(true)

        // call inventory api here
        await inventory_bulk_upload(formData)

        // refetch inventory
        await fetchInventory()
        await fetchProducts()
        setLoading(false)
      } catch (error) {
        toast.info(error?.response)
        setLoading(false)
        errorToast(error?.data?.status)
      }
    }
  }

  const onUpdateProduct = async (dd) => {
    console.log('dd', dd)
    const exists = data.find((d) => d.product === dd.product)

    await inventoryUpdateService(exists.id, {
      ...exists,
      quantity: dd.quantity,
      selling_price: parseFloat(dd.selling_price),
    })
    successToast('Inventory updated')
    await fetchInventory()
    await fetchProducts()
    setManualForm(false)
    setSelectForm(false)
  }

  const onScanSelect = async (values) => {
    console.log('product', values)
    // if product already exists in inventory then update its quantity
    const exists = data.find((d) => d.product === values.product)
    if (exists) {
      // product exists update
      console.log('product is', exists)
      onTableRowClick(exists)
    } else {
      await fetchInventory()
      await fetchProducts()
      successToast('Product created and inventory updated')
    }
  }

  const handleScannedProduct = async (product) => {
    const res = await inventoryListService(company?.id)
    const prodt = res.data?.find((ivp) => ivp?.product === product.id)
    onScanSelect(prodt)
  }

  return (
    <SidebarLayout title="Inventory">
      <div className="flex space-x-3 items-center">
        <AddButton onClick={openForm} title="Add Item" />
        <AddButton
          onClick={() => setScanWithCamera(true)}
          title="Scan item with camera"
        />
        <AddButton
          onClick={() => setSelectForm(true)}
          title="Scan item with device"
        />
        <AddButton onClick={handleUploadButton} title="Upload Inventory" />
        <a href="/sample.csv" className="underline">
          Download Sample
        </a>
      </div>
      <InventoryTable
        data={data}
        onEdit={openUpdateForm}
        onDelete={openDeleteModal}
        productList={productList}
      />
      <InventoryAddForm
        company={company}
        productList={productList}
        open={addForm}
        onClose={closeForm}
        title="Add Item"
        onAdd={onInventoryAdd}
      />
      <InventoryUpdateForm
        company={company}
        open={updateForm}
        data={updateFormData}
        onClose={closeUpdateForm}
        title="Update Item"
        onUpdate={onInventoryUpdate}
        productList={productList}
      />
      <DeleteConfirmation
        open={deleteModel}
        onClose={closeDeleteModal}
        onDelete={onInventoryDelete}
      />
      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        onClick={onFileClick}
        onChange={onFileChange}
        ref={inputRef}
      />
      <StockAlert />
      {scanWithCamera && (
        <ScanWithCamera
          onClose={() => setScanWithCamera(false)}
          onSuccessScan={handleScannedProduct}
          open={scanWithCamera}
          key={'scan_with_camera'}
        />
      )}

      {manualForm && (
        <SelectManualProduct
          initialValue={initialValue}
          open={manualForm}
          onClose={() => setManualForm(false)}
          productList={productList}
          inventoryList={data}
          onAdd={onUpdateProduct}
          title="Select Product"
        />
      )}

      {selectForm && (
        <ScanProduct
          open={selectForm}
          onClose={() => setSelectForm(false)}
          onSuccessScan={handleScannedProduct}
        />
      )}
    </SidebarLayout>
  )
}
