import React from 'react'
import { Routes, Route } from 'react-router-dom'
import CompaniesListPage from './pages/CompaniesListPage'
import { ToastContainer } from 'react-toastify'

import LoginPage from './pages/LoginPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import HomePage from './pages/HomePage'
import ProductsPage from './pages/ProductsPage'
import WelcomePage from './pages/WelcomePage'
import ProtectedRoute from './components/ProtectedRoute'
import { CompanyContext } from './context/CompanyContext'
import CustomerPage from './pages/CustomerPage'
import InventoryPage from './pages/InventoryPage'
import RegisterPage from './pages/RegisterPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import PasswordChangePage from './pages/PasswordChangePage'
import BookKeepingPage from './pages/BookKeepingPage'
import ReportsPage from './pages/ReportsPage'
import IncomePage from './pages/IncomePage'
import ExpensePage from './pages/ExpensePage'
import InvoicePage from './pages/InvoicePage'
import ReceiptPage from './pages/ReceiptPage'
import SalesReportPage from './pages/SalesReportPage'
import ProfitLossReportPage from './pages/ProfitLossReportPage'
import InventoryReportPage from './pages/InventoryReportPage'
import ProductReportPage from './pages/ProductReportPage'
import CustomerReportPage from './pages/CustomerReportPage'
import { LoadingContext } from './context/LoadingContext'
import LoadingOverlay from 'react-loading-overlay'
import RegistrationRedirect from './pages/RegistrationRedirect'
import Footer from './components/Footer'
import Products from './pages/features/Products'
import Customers from './pages/features/Customers'
import Inventory from './pages/features/Inventory'
import BookKeeping from './pages/features/BookKeeping'
import Reports from './pages/features/Reports'
import CheckoutPage from './pages/CheckoutPage'
import AccountDeletePage from './pages/AccountDeletePage'

function App() {
  const [company, setCompany] = React.useState(
    JSON.parse(localStorage.getItem('selectedCompany'))
  )
  const [loading, setLoading] = React.useState(false)

  return (
    <LoadingContext.Provider value={{ setLoading }}>
      <CompanyContext.Provider value={{ company, setCompany }}>
        <LoadingOverlay active={loading} spinner text="Please Wait...">
          <ToastContainer />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="forgot" element={<ForgotPasswordPage />} />
            <Route
              path="companies"
              element={(() => (
                <ProtectedRoute>
                  <CompaniesListPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="welcome"
              element={(() => (
                <ProtectedRoute>
                  <WelcomePage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="account-delete"
              element={(() => (
                <AccountDeletePage />
              ))()}
            />
            <Route
              path="products"
              element={(() => (
                <ProtectedRoute>
                  <ProductsPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="customers"
              element={(() => (
                <ProtectedRoute>
                  <CustomerPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="inventory"
              element={(() => (
                <ProtectedRoute>
                  <InventoryPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="book-keeping"
              element={(() => (
                <ProtectedRoute>
                  <BookKeepingPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="checkout"
              element={(() => (
                <ProtectedRoute>
                  <CheckoutPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="book-keeping/income"
              element={(() => (
                <ProtectedRoute>
                  <IncomePage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="book-keeping/expense"
              element={(() => (
                <ProtectedRoute>
                  <ExpensePage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="book-keeping/invoice"
              element={(() => (
                <ProtectedRoute>
                  <InvoicePage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="book-keeping/receipt"
              element={(() => (
                <ProtectedRoute>
                  <ReceiptPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="reports"
              element={(() => (
                <ProtectedRoute>
                  <ReportsPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="reports/sales"
              element={(() => (
                <ProtectedRoute>
                  <SalesReportPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="reports/profit-loss"
              element={(() => (
                <ProtectedRoute>
                  <ProfitLossReportPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="reports/inventory"
              element={(() => (
                <ProtectedRoute>
                  <InventoryReportPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="reports/product"
              element={(() => (
                <ProtectedRoute>
                  <ProductReportPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="reports/customer"
              element={(() => (
                <ProtectedRoute>
                  <CustomerReportPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="privacy-policy"
              element={(() => (
                <ProtectedRoute>
                  <PrivacyPolicyPage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="change-password"
              element={(() => (
                <ProtectedRoute>
                  <PasswordChangePage />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="features/products"
              element={(() => (
                <ProtectedRoute>
                  <Products />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="features/customers"
              element={(() => (
                <ProtectedRoute>
                  <Customers />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="features/inventory"
              element={(() => (
                <ProtectedRoute>
                  <Inventory />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="features/bookkeeping"
              element={(() => (
                <ProtectedRoute>
                  <BookKeeping />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="features/reports"
              element={(() => (
                <ProtectedRoute>
                  <Reports />
                </ProtectedRoute>
              ))()}
            />
            <Route
              path="registration/account-confirm-email/*"
              element={<RegistrationRedirect />}
            />
          </Routes>
        </LoadingOverlay>
      </CompanyContext.Provider>
    </LoadingContext.Provider>
  )
}

export default App
