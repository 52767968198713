import { useEffect, useState } from 'react'
import CustomModal from '../../Modal'
import SelectProduct from './SelectProduct'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const closeIcon = require('../../../assets/close_icon.png')

const ReceiptSchema = Yup.object().shape({
  products: Yup.array().min(1, 'Products are required').required('Required'),
  company: Yup.number().required('Required'),
})

export default function ReceiptAddForm({
  open,
  onClose,
  title,
  onAdd,
  productList,
  inventoryList,
  company,
}) {
  const [selectForm, setSelectForm] = useState(false)
  const [initialValue, setInitialValue] = useState({
    quantity: 0,
    price: inventoryList[0]?.selling_price,
    product: (inventoryList?.filter((x) => x.quantity > 0))[0]?.product,
  })

  const formik = useFormik({
    initialValues: {
      products: [],
      company: company.id,
      total_receipt_amount: 0,
    },
    // enableReinitialize: true,
    validationSchema: ReceiptSchema,
    onSubmit: (values, { resetForm }) => {
      onAdd(values)
      // resetForm();
    },
  })

  useEffect(() => {
    setInitialValue({
      quantity: 0,
      price: inventoryList[0]?.selling_price,
      product: (inventoryList?.filter((x) => x.quantity > 0))[0]?.product,
    })
  }, [inventoryList])

  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const openForm = () => {
    if (inventoryList?.length === 0) {
      alert(
        'there are no products in the company inventory. You can only create income and receipts for products in the inventory.'
      )
    } else {
      setSelectForm(true)
    }
  }

  const closeSelectForm = () => {
    setSelectForm(false)
  }

  const prod_id_name_mapping = {}
  for (let i = 0; i < productList.length; i++) {
    prod_id_name_mapping[productList[i].id] = productList[i].name
  }

  const onSelect = (values) => {
    let isItemExists = formik.values.products.find(
      (item) => +item.id === +values.product
    )
    if (!isItemExists) {
      formik.setFieldValue('products', [
        ...formik.values.products,
        {
          id: values.product,
          price: parseFloat(values.price),
          quantity: values.quantity,
        },
      ])
    } else {
      // update the item
      formik.setFieldValue(
        'products',
        formik.values.products.map((item) => {
          return +item.id === +values.product
            ? {
                id: values.product,
                price: parseFloat(values.price),
                quantity: values.quantity,
              }
            : item
        })
      )
    }

    setSelectForm(false)
  }

  const onTableRowClick = (item) => {
    setInitialValue({
      quantity: item.quantity,
      price: item.price,
      product: item.id,
    })
    setSelectForm(true)
  }

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <div onClick={openForm} className="btn-primary max-w-max">
            Add Item
          </div>
          <span className="block my-2 text-base font-semibold">
            Selected Product List{' '}
          </span>
          <table className="w-full">
            <tr className="bg-gray-600 text-white">
              <th className="font-medium">Name</th>
              <th className="font-medium">Price</th>
              <th className="font-medium">Quantity</th>
              <th className="font-medium">Action</th>
            </tr>
            {formik.values.products.map((item) => (
              <tr className="bg-gray-100" onClick={() => onTableRowClick(item)}>
                <td className="text-center">
                  {/*productList.find((prod) => prod.id === item.id).name*/}
                  {prod_id_name_mapping[item.id]}
                </td>
                <td className="text-center">{`${
                  company.currency_symbol || company.currency_symbol_code
                } ${item.price}`}</td>
                <td className="text-center">{item.quantity}</td>
                <td className="flex justify-center cursor-pointer">
                  <span onClick={() => onTableRowClick(item)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-center"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </span>
                </td>
              </tr>
            ))}
            {formik.values.products.length === 0 && (
              <span>0 item selected</span>
            )}
          </table>
          {formik.errors.products && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.products}
            </div>
          )}
          <div className="flex space-x-3">
            <button
              type="submit"
              className="btn-primary max-w-max mt-4 disabled:bg-gray-400"
              disabled={!(formik.isValid && formik.dirty)}
            >
              Submit
            </button>
            <button onClick={onClose} className="btn-primary max-w-max mt-4">
              Cancel
            </button>
          </div>
        </form>
      </div>
      <SelectProduct
        initialValue={initialValue}
        open={selectForm}
        onClose={closeSelectForm}
        productList={productList}
        inventoryList={inventoryList}
        onAdd={onSelect}
        title="Select Product"
      />
    </CustomModal>
  )
}
