import ExpenseTable from './ExpenseTable';

export default function Expense({ onAddClick, data, onEdit, onDelete }) {
  return (
    <div className="mt-10">
      <button onClick={onAddClick} className="btn-primary max-w-max">
        Add Expense
      </button>
      <ExpenseTable data={data} onDelete={onDelete} onEdit={onEdit} />
    </div>
  );
}
