/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomModal from '../../Modal'

const closeIcon = require('../../../assets/close_icon.png')

const ExpenseSchema = Yup.object().shape({
  description: Yup.string().required('Required').min(5).max(300),
  amount: Yup.number().required('Required').min(0.01),
  company: Yup.number().required('Required'),
})

export default function ExpenseAddForm({
  open,
  onClose,
  title,
  onAdd,
  company,
}) {
  const formik = useFormik({
    initialValues: {
      description: '',
      amount: 0,
      company: company.id,
    },
    validationSchema: ExpenseSchema,
    onSubmit: (values, { resetForm }) => {
      onAdd(values)
      resetForm()
    },
  })

  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
  }, [open])

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <label htmlFor="name">Description</label>
          <input
            id="description"
            name="description"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
          {formik.errors.description && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.description}
            </div>
          )}
          <label htmlFor="address">Amount</label>
          <input
            id="amount"
            name="amount"
            className="border mb-2 p-2"
            onChange={(e) =>
              formik.setFieldValue(
                'amount',
                e.target.value
                  .split(
                    company.currency_symbol || company.currency_symbol_code
                  )[1]
                  .trim()
              )
            }
            value={`${
              company.currency_symbol || company.currency_symbol_code
            } ${formik.values.amount}`}
          />
          {formik.errors.amount && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.amount}
            </div>
          )}
          <label htmlFor="company">Company</label>
          <input className="border mb-2 p-2" value={company.name} />

          <div className="space-y-3">
            <button
              type="submit"
              className="btn-primary disabled:bg-gray-400"
              disabled={!(formik.isValid && formik.dirty)}
            >
              Submit
            </button>
            <button onClick={onClose} className="btn-primary">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  )
}
