import { instance } from "./base.service";

export const addInvoiceService = (data) => {
  return instance.post("invoice/", data);
};

export const listInvoiceService = (id) => {
  return instance.get(`invoice/company/${id}/`);
};

export const deleteInvoiceService = (id) => {
  return instance.delete(`invoice/${id}/`);
};

export const sendInvoiceEmailService = (id) => {
  return instance.get(`invoice/email/${id}/`);
};
