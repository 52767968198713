import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomModal from './Modal'
import PhoneInput from 'react-phone-number-input'
import CountrySelect from './CountrySelect'

const closeIcon = require('../assets/close_icon.png')

const CompanySchema = Yup.object().shape({
  name: Yup.string().required('Required').min(5).max(50),
  address: Yup.string().required('Required').min(5).max(60),
  phone_number: Yup.number().required('Required'),
  email: Yup.string().email().required('Required'),
  vat: Yup.bool().required('Required'),
  country: Yup.number().required('Required'),
  vat_percentage: Yup.number().notRequired(''),
  currency_symbol: Yup.string().required('Required'),
})

export default function CompanyUpdateForm({
  open,
  onClose,
  title,
  onUpdate,
  data,
}) {
  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      phone_number: '',
      email: '',
      vat: false,
      country: 226,
      currency_symbol: 'GBP',
      vat_percentage: 20,
    },
    validationSchema: CompanySchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      onUpdate(values)
      resetForm()
    },
  })

  useEffect(() => {
    if (data) {
      console.log('update form data', data)
      const { setValues } = formik
      setValues(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open])

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            name="name"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.errors.name && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.name}
            </div>
          )}
          <label htmlFor="address">Address</label>
          <input
            id="address"
            name="address"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.address}
          />
          {formik.errors.address && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.address}
            </div>
          )}
          <label htmlFor="phone_number">Phone Number</label>
          <PhoneInput
            placeholder="Enter phone number"
            value={formik.values.phone_number}
            className="border mb-2 p-2"
            onChange={(val) => formik.setFieldValue('phone_number', val)}
          />
          {formik.errors.phone_number && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.phone_number}
            </div>
          )}
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.email}
            </div>
          )}
          <label htmlFor="email">Select Country</label>

          <CountrySelect
            defaultValue={formik.values.country}
            handleChange={formik.setFieldValue}
          />
          {formik.errors.country && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.country}
            </div>
          )}

          <div>
            <label className="relative flex justify-between items-center mb-2 ">
              Vat
              <input
                onChange={formik.handleChange}
                checked={formik.values.vat}
                name="vat"
                id="vat"
                type="checkbox"
                className="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md"
              />
              <span className="w-10 h-5 flex items-center flex-shrink-0  p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-primary after:w-4 after:h-4 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-4 " />
            </label>
          </div>

          {formik.values.vat && (
            <>
              <label htmlFor="vat_percentage">Vat Percentage</label>

              <input
                id="vat_percentage"
                name="vat_percentage"
                type="number"
                min={1}
                max={100}
                defaultValue={20}
                className="border mb-2 p-2"
                onChange={formik.handleChange}
                value={formik.values.vat_percentage}
              />
              {formik.errors.vat_percentage && (
                <div className="text-xs mb-2 text-red-500">
                  {formik.errors.vat_percentage}
                </div>
              )}
            </>
          )}

          <div className="space-y-3">
            <button type="submit" className="btn-primary">
              Update
            </button>
            <button onClick={onClose} className="btn-primary">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  )
}
