import React, { useContext, useEffect, useState } from "react";
import AddButton from "../components/AddButton";
import DeleteConfirmation from "../components/DeleteConfirmation";
import ProductAdd from "../components/ProductAdd";
import ProductTable from "../components/ProductTable";
import ProductUpdateForm from "../components/ProductUpdateForm";
import { CompanyContext } from "../context/CompanyContext";
import { LoadingContext } from "../context/LoadingContext";
import StockAlert from "../components/StoctAlert";
import SidebarLayout from "../layout/SidebarLayout";
import {
  productAddService,
  productDeleteService,
  productsListService,
  productUpdateService,
} from "../services/products.service";

export default function ProductsPage() {
  const [data, setData] = useState([]);
  const [addForm, showAddForm] = React.useState(false);
  const { company } = useContext(CompanyContext);
  const { setLoading } = useContext(LoadingContext);
  const [updateForm, showUpdateForm] = React.useState(false);
  const [updateFormData, setUpdateFormData] = React.useState({});
  const [deleteModel, showDeleteModal] = React.useState(false);
  const [currentDeleteId, setCurrentDeleteId] = React.useState(null);

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchProducts() {
    try {
      setLoading(true);
      const res = await productsListService(company?.id);
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function openDeleteModal(data) {
    const { id } = data;
    setCurrentDeleteId(id);
    showDeleteModal(true);
  }

  function closeDeleteModal() {
    showDeleteModal(false);
  }

  function openForm() {
    showAddForm(true);
  }

  function closeForm() {
    showAddForm(false);
  }

  function openUpdateForm(data) {
    showUpdateForm(true);
    setUpdateFormData(data);
  }

  function closeUpdateForm() {
    showUpdateForm(false);
  }

  async function onProductAdd(data) {
    try {
      setLoading(true);
      await productAddService(data);
      await fetchProducts();
      setLoading(false);
      closeForm();
    } catch (error) {
      setLoading(false);
    }
  }

  async function onProductDelete() {
    try {
      setLoading(true);
      await productDeleteService(currentDeleteId);
      await fetchProducts();
      closeDeleteModal();
      setCurrentDeleteId(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function onProductUpdate(data) {
    try {
      setLoading(true);
      const { id } = updateFormData;
      await productUpdateService(id, data);
      await fetchProducts();
      setUpdateFormData({});
      closeUpdateForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  return (
    <SidebarLayout title="Products">
      <AddButton onClick={openForm} title="Add Product" />
      <ProductTable
        data={data}
        onEdit={openUpdateForm}
        onDelete={openDeleteModal}
      />
      <ProductAdd
        company={company}
        open={addForm}
        onClose={closeForm}
        title="Add Product"
        onAdd={onProductAdd}
      />
      <ProductUpdateForm
        company={company}
        open={updateForm}
        data={updateFormData}
        onClose={closeUpdateForm}
        title="Update Product"
        onUpdate={onProductUpdate}
      />
      <DeleteConfirmation
        open={deleteModel}
        onClose={closeDeleteModal}
        onDelete={onProductDelete}
      />
      <StockAlert />
    </SidebarLayout>
  );
}
