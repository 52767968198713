import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100%',
  },
  overlay: {
    zIndex: '10',
  },
}

Modal.setAppElement('#root')

export default function CustomModal({ open, onClose, title, children }) {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel={title}
      shouldCloseOnOverlayClick={false}
    >
      {children}
    </Modal>
  )
}
