import { useContext, useEffect, useState } from "react";
import AddExpense from "../components/bookkeeping/expense/AddExpense";
import Expense from "../components/bookkeeping/expense/Expense";
import { CompanyContext } from "../context/CompanyContext";
import { LoadingContext } from "../context/LoadingContext";
import SidebarLayout from "../layout/SidebarLayout";
import {
  addExpenseService,
  deleteExpenseService,
  listExpenseService,
} from "../services/expense.service";

export default function ExpensePage() {
  const { company } = useContext(CompanyContext);
  const { setLoading } = useContext(LoadingContext);
  const [addForm, setAddForm] = useState(false);
  const [listExpense, setListExpense] = useState([]);

  useEffect(() => {
    fetchExpense();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchExpense() {
    try {
      setLoading(true);
      const res = await listExpenseService(company?.id);
      setListExpense(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onAddClick = () => {
    setAddForm(true);
  };

  const closeAddForm = () => {
    setAddForm(false);
  };

  const onAdd = async (data) => {
    try {
      setLoading(true);
      await addExpenseService(data);
      await fetchExpense();
      closeAddForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onDelete = async ({ id }) => {
    try {
      setLoading(true);
      await deleteExpenseService(id);
      await fetchExpense();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <SidebarLayout title="Expense">
      <Expense
        onAddClick={onAddClick}
        data={listExpense}
        onEdit={() => {}}
        onDelete={onDelete}
      />
      <AddExpense
        company={company}
        open={addForm}
        onClose={closeAddForm}
        title="Add New Expense"
        onAdd={onAdd}
      />
    </SidebarLayout>
  );
}
