import React from 'react';

import Table from '../Table';

function DaySaleTable({ data, onEdit, onDelete, productList }) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'amount',
        accessor: 'income',
        Cell: ({ value }) => value.toFixed(2),
      },
      {
        Header: 'Sale Date',
        accessor: 'created_at',
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      onEdit={onEdit}
      onDelete={onDelete}
      dontShowButtons
      dontUseFixedHeight
    />
  );
}

export default DaySaleTable;
