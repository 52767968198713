import React, { useContext, useEffect, useState } from 'react'
import CustomModal from '../../Modal'
import SelectProduct from './SelectProduct'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomerAddForm from '../../customer/CustomerAddForm'
import AddButton from '../../AddButton'
import { LoadingContext } from '../../../context/LoadingContext'

import {
  customerAddService,
  customerListService,
} from '../../../services/customer.service'

const closeIcon = require('../../../assets/close_icon.png')
const addIcon = require('../../../assets/add_icon.png')

const InvoiceSchema = Yup.object().shape({
  products: Yup.array().min(1, 'Products are required').required('Required'),
  customer: Yup.number().required('Required'),
  company: Yup.number().required('Required'),
})

export default function InvoiceAddForm({
  open,
  onClose,
  title,
  onAdd,
  productList,
  company,
  inventoryList,
}) {
  const [selectForm, setSelectForm] = useState(false)
  const [addForm, showAddForm] = React.useState(false)
  const { setLoading } = useContext(LoadingContext)
  const [data, setData] = useState([])
  const [initialValue, setInitialValue] = useState({
    quantity: 0,
    price: inventoryList[0]?.selling_price,
    product: (inventoryList?.filter((x) => x.quantity > 0))[0]?.product,
  })

  const formik = useFormik({
    initialValues: {
      products: [],
      //customer: data[0]?.id || 0,
      customer: data.slice(-1)[0]?.id || 0,
      company: company.id,
    },
    enableReinitialize: true,
    validationSchema: InvoiceSchema,
    onSubmit: (values, { resetForm, setFieldError }) => {
      console.log('values', values)
      if (values.customer === 0) {
        setFieldError('customer', 'Customer is required')
      } else {
        onAdd(values)
      }
      // resetForm();
    },
  })

  useEffect(() => {
    setInitialValue({
      quantity: 0,
      price: inventoryList[0]?.selling_price,
      product: (inventoryList?.filter((x) => x.quantity > 0))[0]?.product,
    })
  }, [inventoryList])

  useEffect(() => {
    if (data.length > 0) {
      formik.setValues({
        company: formik.values.company,
        customer: data[0].id,
        products: formik.values.products,
      })
      console.log('customer_data', initialValue)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const openAddForm = () => {
    showAddForm(true)
  }

  const closeAddForm = () => {
    showAddForm(false)
    //fetchCustomer();
  }

  const prod_id_name_mapping = {}
  for (let i = 0; i < productList.length; i++) {
    prod_id_name_mapping[productList[i].id] = productList[i].name
  }

  async function onCustomerAdd(data) {
    try {
      setLoading(true)
      await customerAddService(data)
      await fetchCustomer()
      closeAddForm()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    //fetchCustomer();
  }, [])

  async function fetchCustomer() {
    try {
      setLoading(true)
      const res = await customerListService(company?.id)
      setData(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCustomer()
    if (open) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const openForm = () => {
    if (inventoryList?.length === 0) {
      alert(
        'there are no products in the company inventory. You can only create income and receipts for products in the inventory.'
      )
    } else {
      setSelectForm(true)
      showAddForm(false)
    }
  }

  const closeSelectForm = () => {
    setSelectForm(false)
  }

  const onSelect = (values) => {
    let isItemExists = formik.values.products.find(
      (item) => +item.id === +values.product
    )
    if (!isItemExists) {
      formik.setFieldValue('products', [
        ...formik.values.products,
        {
          id: values.product,
          price: parseFloat(values.price),
          quantity: values.quantity,
        },
      ])
    } else {
      // update the item
      formik.setFieldValue(
        'products',
        formik.values.products.map((item) => {
          return +item.id === +values.product
            ? {
                id: values.product,
                price: parseFloat(values.price),
                quantity: values.quantity,
              }
            : item
        })
      )
    }

    setSelectForm(false)
  }

  const onTableRowClick = (item) => {
    setInitialValue({
      quantity: item.quantity,
      price: item.price,
      product: item.id,
    })
    setSelectForm(true)
  }

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <div onClick={openForm} className="btn-primary max-w-max">
            Add Item
          </div>
          <span className="block my-2 text-base font-semibold">
            Selected Product List{' '}
          </span>
          <table className="w-full">
            <tr className="bg-gray-600 text-white">
              <th className="font-medium">Name</th>
              <th className="font-medium">Price</th>
              <th className="font-medium">Quantity</th>
              <th className="font-medium">Action</th>
            </tr>
            {formik.values.products.map((item) => (
              <tr className="bg-gray-100">
                <td className="text-center">
                  {/*productList.find((prod) => prod.id === item.id).name*/}
                  {prod_id_name_mapping[item.id]}
                </td>
                <td className="text-center">{`${
                  company.currency_symbol || company.currency_symbol_code
                } ${item.price}`}</td>
                <td className="text-center">{item.quantity}</td>
                <td className="flex justify-center cursor-pointer">
                  <span onClick={() => onTableRowClick(item)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-center"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </span>
                </td>
              </tr>
            ))}
            {formik.values.products.length === 0 && (
              <span>0 item selected</span>
            )}
          </table>
          {formik.errors.products && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.products}
            </div>
          )}
          <div className="w-full flex space-x-2 mt-4">
            <div className="w-full">
              <label htmlFor="product text-base font-semibold">Customer</label>
              <select
                id="customer"
                name="customer"
                value={formik.values.customer}
                onChange={formik.handleChange}
                className="border mb-2 p-2 w-full"
              >
                {data?.map((customer) => (
                  <option value={customer.id}>{customer.name}</option>
                ))}
              </select>
            </div>
            <img
              src={addIcon}
              alt="add-icon"
              className="object-contain mt-3 cursor-pointer"
              onClick={openAddForm}
            />
          </div>
          {formik.errors.customer && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.customer}
            </div>
          )}

          <div className="flex space-x-3">
            <button
              type="submit"
              className="btn-primary max-w-max mt-4 disabled:bg-gray-400"
              disabled={!(formik.isValid && formik.dirty)}
            >
              Submit
            </button>
            <button onClick={onClose} className="btn-primary max-w-max mt-4">
              Cancel
            </button>
          </div>
        </form>
      </div>
      <SelectProduct
        initialValue={initialValue}
        open={selectForm}
        onClose={closeSelectForm}
        inventoryList={inventoryList}
        productList={productList}
        onAdd={onSelect}
        title="Select Product"
      />
      {openAddForm}
      <CustomerAddForm
        company={company}
        open={addForm}
        onClose={closeAddForm}
        title="Add Customer"
        onAdd={onCustomerAdd}
      />
    </CustomModal>
  )
}
