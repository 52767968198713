import { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';

export default function HomePage(props) {
  const navigation = useNavigate()
  useEffect(() =>{
    localStorage.getItem('token') ? navigation('/welcome') : navigation('/login')
  }, [])
  return (<p>loading...</p>)
}
