import CompanyIcon from '../assets/company_icon.png';
import EditIcon from '../assets/edit-icon.png';
import DeleteIcon from '../assets/delete-icon.png';

export default function CompanyCard({ data, onDelete, onEdit, onSelect }) {

  return (
    <div
      className={`flex justify-between items-center space-x-2 py-4 px-2 
      cursor-pointer hover:shadow-md hover:border`}
    >
      <div className="flex space-x-2" onClick={() => onSelect(data)}>
        <img
          className="rounded-full w-10 h-10 object-contain bg-gray-400"
          src={CompanyIcon}
          alt="company-icon"
        />
        <div className="flex flex-col">
          <span className="font-bold text-md">{data.name}</span>
          <span className="text-gray-700 text-sm">{data.address}</span>
        </div>
      </div>
      <div className="flex space-x-2">
        <button
          className="flex space-x-1 items-center rounded-sm border
        hover:bg-gray-200 border-gray-200 bg-gray-100 px-1"
          onClick={() => onEdit(data)}
        >
          <img src={EditIcon} alt="edit-icon" className="w-3 h-3" />
          <span className="text-xs">edit</span>
        </button>
        <button
          onClick={() => onDelete(data)}
          className="flex space-x-1 items-center rounded-sm border
        hover:bg-gray-200 border-gray-200 bg-gray-100 px-1"
        >
          <img src={DeleteIcon} alt="edit-icon" className="w-3 h-3" />
          <span className="text-xs">delete</span>
        </button>
      </div>
    </div>
  );
}
