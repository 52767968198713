import { useFormik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import moment from "moment";
import ButtonLoading from "./ButtonLoading";

const ReportFilterSchema = Yup.object().shape({
  startDate: Yup.date().required("Required"),
  endDate: Yup.date().required("Required"),
});

export default function ReportFilter({ onSearch, onSendEmail }) {
  const formik = useFormik({
    initialValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
    validationSchema: ReportFilterSchema,
    onSubmit: (values, { setSubmitting }) => {
      onSearch(
        {
          startDate: moment(values.startDate).format("YYYY-MM-DD"),
          endDate: moment(values.endDate).format("YYYY-MM-DD"),
        },
        setSubmitting
      );
    },
  });

  const onEmail = () => {
    onSendEmail(
      {
        startDate: moment(formik.values.startDate).format("YYYY-MM-DD"),
        endDate: moment(formik.values.endDate).format("YYYY-MM-DD"),
      },
      formik.setSubmitting
    )
  }

  return (
    <form onSubmit={formik.handleSubmit} className="flex space-x-2 mt-4">
      <div className="flex flex-col">
        <label htmlFor="name">Start Date</label>
        <DatePicker
          selected={formik.values.startDate}
          id="startDate"
          name="startDate"
          onChange={(date) => formik.setFieldValue("startDate", date)}
          className="border mb-2 p-2"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="description">End Date</label>
        <DatePicker
          selected={formik.values.endDate}
          id="endDate"
          name="endDate"
          onChange={(date) => formik.setFieldValue("endDate", date)}
          className="border mb-2 p-2"
        />
      </div>
      <div className="flex justify-center space-x-2 items-end mb-2">
        <button type="submit" className=" btn-primary flex max-w-max">
          Find
          {formik.isSubmitting && <ButtonLoading />}
        </button>
        
      </div>
    </form>
  );
}
