import StockAlert from "../components/StoctAlert";
import BookKeeping from "../components/bookkeeping/BookKeeping";
import SidebarLayout from "../layout/SidebarLayout";

const data = [
  // {
  //   title: "Income",
  //   url: "/book-keeping/income",
  // },
  {
    title: "Expense",
    url: "/book-keeping/expense",
  },
  {
    title: "Invoice",
    url: "/book-keeping/invoice",
  },

  {
    title: "Receipt",
    url: "/book-keeping/receipt",
  },

];

export default function BookKeepingPage() {
  return (
    <SidebarLayout title="Book Keeping">
      <BookKeeping data={data} />
      <StockAlert />
    </SidebarLayout>
  );
}
