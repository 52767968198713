/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormikContext } from 'formik';

import Logo from '../../assets/logo.png';
import Footer from '../Footer';

export default function ChangePassword() {
  const {
    values,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
  } = useFormikContext();

  return (
    <div>
      <section className="w-screen h-screen bg-bg">
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div className="flex justify-center">
              <a href="/welcome" className="relative text-primary">
                <img
                  src={Logo}
                  alt="logo"
                  className="w-24 h-24 object-contain"
                />
                <span className="absolute text-base text-center left-24 top-4 border border-primary rounded-full w-5 h-5">
                  &trade;
                </span>
              </a>
            </div>
            <span className="text-bold text-xl text-primary font-bold">
              Change Password
            </span>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md shadow-sm space-y-2">
                <div>
                  <label htmlFor="old_password" className="text-gray-700 mb-2">
                    Old Password
                  </label>
                  <input
                    id="old_password"
                    name="old_password"
                    type="password"
                    value={values.old_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="old_password"
                    className="login-field"
                    placeholder="Old Password"
                  />
                  {errors.old_password && (
                    <div className="text-xs mb-2 text-red-500">
                      {errors.old_password}
                    </div>
                  )}
                </div>
                <div>
                  <label htmlFor="new_password1" className="text-gray-700 mb-2">
                    New Password
                  </label>
                  <input
                    id="new_password1"
                    name="new_password1"
                    type="password"
                    value={values.new_password1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="current-new_password1"
                    className="login-field"
                    placeholder="New Password"
                  />
                  {errors.new_password1 && (
                    <div className="text-xs mb-2 text-red-500">
                      {errors.new_password1}
                    </div>
                  )}
                </div>
                <div>
                  <label htmlFor="new_password2" className="text-gray-700 mb-2">
                    Confirm Password
                  </label>
                  <input
                    id="new_password2"
                    name="new_password2"
                    type="password"
                    value={values.new_password2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="current-new_password2"
                    className="login-field"
                    placeholder="New Password"
                  />
                  {errors.new_password2 && (
                    <div className="text-xs mb-2 text-red-500">
                      {errors.new_password2}
                    </div>
                  )}
                </div>
              </div>

              <div>
                <button type="submit" className="btn-primary">
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                  Submit
                  {isSubmitting && (
                    <svg
                      class="animate-spin ml-4 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <div className="sm:absolute left-0 bottom-0 right-0 bg-white">
        <Footer />
      </div>
    </div>
  );
}
