import InvoiceTable from './InvoiceTable';

export default function Invoice({ onAddClick, data, onEdit, onDelete }) {
  return (
    <div className="mt-10">
      <button onClick={onAddClick} className="btn-primary max-w-max mb-2">
        Add Invoice
      </button>
      <InvoiceTable data={data} onDelete={onDelete} onEdit={onEdit} />
    </div>
  );
}
