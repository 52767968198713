/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import CompanyAddForm from '../components/CompanyAddForm'
import CompaniesList from '../components/CompanyList'
import CompanyUpdateForm from '../components/CompanyUpdateForm'
import DeleteConfirmation from '../components/DeleteConfirmation'
import { CompanyContext } from '../context/CompanyContext'
import { LoadingContext } from '../context/LoadingContext'
import {
  getCompanyListService,
  deleteCompanyService,
  addCompanySerivce,
  updateCompanyService,
} from '../services/company.service'

import countriesJSON from '../countries.json'

export default function CompaniesListPage() {
  const navigate = useNavigate()
  const { setCompany } = useContext(CompanyContext)
  const { setLoading } = useContext(LoadingContext)
  const [companyList, setCompanyList] = useState([])
  const [addForm, showAddForm] = React.useState(false)
  const [updateForm, showUpdateForm] = React.useState(false)
  const [updateFormData, setUpdateFormData] = React.useState({})
  const [deleteModel, showDeleteModal] = React.useState(false)
  const [currentDeleteId, setCurrentDeleteId] = React.useState(null)

  useEffect(() => {
    fetchCompanyList()
  }, [])

  const fetchCompanyList = async () => {
    try {
      setLoading(true)
      const res = await getCompanyListService()
      setCompanyList(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const deleteCompany = async () => {
    try {
      setLoading(true)
      await deleteCompanyService(currentDeleteId)
      await fetchCompanyList()
      closeDeleteModal()
      setCurrentDeleteId(null)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onBack = () => {
    navigate(-1)
  }

  function openForm() {
    showAddForm(true)
  }

  function closeForm() {
    showAddForm(false)
  }

  function openUpdateForm(data) {
    showUpdateForm(true)
    setUpdateFormData(data)
  }

  function closeUpdateForm() {
    showUpdateForm(false)
  }

  async function onCompanyAdd(data) {
    try {
      setLoading(true)
      await addCompanySerivce(data)
      await fetchCompanyList()
      setLoading(false)
      closeForm()
    } catch (error) {
      setLoading(false)
    }
  }

  async function onCompanyUpdate(data) {
    try {
      setLoading(true)
      const { id } = updateFormData
      await updateCompanyService(id, data)
      await fetchCompanyList()
      setUpdateFormData({})
      closeUpdateForm()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  function onCompanySelect(data) {
    const country = countriesJSON.find((c) => c.id === data.country)

    setCompany({
      id: data.id,
      name: data.name,
      country: data.country,
      currency_symbol: country.currency.symbol,
      currency_symbol_code: country.currency.code,
    })
    console.log('country...', data.id, country)
    localStorage.setItem(
      'selectedCompany',
      JSON.stringify({
        id: data.id,
        name: data.name,
        country: data.country,
        currency_symbol_code: country.currency.code,
        currency_symbol: country.currency.symbol,
      })
    )
    navigate('/products')
  }

  function openDeleteModal(data) {
    const { id } = data
    setCurrentDeleteId(id)
    showDeleteModal(true)
  }

  function closeDeleteModal() {
    showDeleteModal(false)
  }

  return (
    <React.Fragment>
      <CompaniesList
        list={companyList}
        onBack={onBack}
        onAdd={openForm}
        onEdit={openUpdateForm}
        onDelete={openDeleteModal}
        onSelect={onCompanySelect}
      />

      <CompanyAddForm
        open={addForm}
        onClose={closeForm}
        title="Add Company"
        onAdd={onCompanyAdd}
      />
      <CompanyUpdateForm
        open={updateForm}
        data={updateFormData}
        onClose={closeUpdateForm}
        title="Update Company"
        onUpdate={onCompanyUpdate}
      />
      <DeleteConfirmation
        open={deleteModel}
        onClose={closeDeleteModal}
        onDelete={deleteCompany}
      />
    </React.Fragment>
  )
}
