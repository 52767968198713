import ReceiptTable from './ReceiptTable';

export default function Receipt({ onAddClick, data, onEdit, onDelete }) {
  return (
    <div className="mt-10">
      <button onClick={onAddClick} className="btn-primary max-w-max mb-2">
        Add Receipt
      </button>
      <ReceiptTable data={data} onDelete={onDelete} onEdit={onEdit} />
    </div>
  );
}
