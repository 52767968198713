import Logo from '../assets/logo.png';

export default function PrivacyPolicyPage () { 
    return (
        <div className="px-10 mx-auto min-h-screen">
            <h1 className="font-bold text-2xl my-4">Privacy Policy</h1>
            <h1 className="block font-bold text-black text-xl">1. Data protection and privacy laws</h1>
            <p className="my-1 text-base block">
            The main laws are the Data Protection Act 2018 and the General
              Data Protection Regulation (GDPR). We also adhere to the duty of
              confidence and the Human Rights Act (Article 8).
            </p>

            <h1 className="block font-bold text-black text-xl">2. DMU is the data controller</h1>
            <p className="my-1 text-base block">
            DMU is the data controller. This means that we determine the
              purpose of the processing and are responsible for the adequate
              protection of personal information.
            </p>

            <p className="my-1 text-base block">
            All our staff are appropriately trained and understand their
              responsibilities for protecting personal data.
            </p>

            <p className="my-1 text-base block">
            When we purchase services or support from a third party, or
              outsource a service or function to a third party, we remain the
              data controller, and our suppliers and service providers must
              adhere to our contract terms and conditions, which include data
              protection and information security requirements.
            </p>

            <h1 className="block font-bold text-black text-xl">3. How to complain</h1>
            <p className="my-1 text-base block">
            If you have any concerns or wish to complain about a data
              protection issue, please contact our Data Protection Officer at
              DPO@dmu.ac.uk
            </p>
            <p className="my-1 text-base block">
            If you are dissatisfied with the way DMU has handled your
              complaint, you have a right to complain to the Information
              Commissioner’s Office at ICO.org.uk
            </p>

            <h1 className="block font-bold text-black text-xl">4. Social media</h1>
            <p className="my-1 text-base block">
            We use information posted publicly on social media so we can make
              information available where it may be relevant or of interest. We
              never attempt to access private social media accounts.
            </p>
            <p className="my-1 text-base block">
            If you raise a query or a complaint through DMU’s social media,
              we’ll of course have a record of your user name. We will only use
              this to resolve your query or complaint and to improve your user
              experience with the university.
            </p>

            <h1 className="block font-bold text-black text-xl">5. How to report a data protection incident</h1>
            <p className="my-1 text-base block">
            Please let us know about any data protection or information
              security incident as soon as possible, by writing to us at
              dataprotection@DMU.ac.uk
            </p>
            <div className="absolute bottom-24 left-10 ">
            <a href="/welcome" className="relative">
              <img src={Logo} alt="bokia-logo" />
              <span className="absolute text-xl text-center text-primary -right-6 top-4 border border-primary rounded-full w-6 h-6">
                &trade;
              </span>
            </a>
          </div>
        </div>
    )
}
