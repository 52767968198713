import { useContext, useEffect, useState } from "react";
import IncomeAddForm from "../components/bookkeeping/income/AddIncome";
import Income from "../components/bookkeeping/income/Income";
import { CompanyContext } from "../context/CompanyContext";
import { LoadingContext } from "../context/LoadingContext";
import SidebarLayout from "../layout/SidebarLayout";
import {
  addIncomeService,
  listIncomeService,
  deleteIncomeService,
} from "../services/income.service";
import { productsListService } from "../services/products.service";
import {inventoryListService} from "../services/inventory.service";


export default function IncomePage() {
  const { company } = useContext(CompanyContext);
  const { setLoading } = useContext(LoadingContext);
  const [addForm, setAddForm] = useState(false);
  const [productList, setProductList] = useState([]);
  const [listIncome, setListIncome] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);


  useEffect(() => {
    fetchProducts();
    fetchInventory()
    fetchIncome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  async function fetchInventory() {
    try {
      setLoading(true);
      const res = await inventoryListService(company?.id);
      setInventoryList(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function fetchProducts() {
    try {
      setLoading(true);
      const res = await productsListService(company?.id);
      setProductList(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function fetchIncome() {
    try {
      setLoading(true);
      const res = await listIncomeService(company?.id);
      setListIncome(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onAddClick = () => {
    setAddForm(true);
  };

  const closeAddForm = () => {
    setAddForm(false);
  };

  const onAdd = async (list) => {
    try {
      setLoading(true);
      await addIncomeService({
        products: list,
        company: company?.id,
      });
      await fetchIncome();
      closeAddForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    finally {
      closeAddForm()
    }
  };

  const onDelete = async ({ id }) => {
    try {
      setLoading(true);
      await deleteIncomeService(id);
      await fetchIncome();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <SidebarLayout title="Income">
      <Income
        onAddClick={onAddClick}
        data={listIncome}
        onEdit={() => {}}
        onDelete={onDelete}
      />
      <IncomeAddForm
        company={company}
        open={addForm}
        onClose={closeAddForm}
        title="Add New Income"
        onAdd={onAdd}
        productList={productList}
        inventoryList={inventoryList}
      />
    </SidebarLayout>
  );
}
