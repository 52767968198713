import React from 'react';

import Table from './Table';

function ProductTable({data, onEdit, onDelete}) {
  const sortedData = data.sort((a, b) => a.name.localeCompare(b.name))
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        width:1,
        Cell: (row) => {
          console.log(row)
            return <div>{parseInt(row.row.id) + 1}</div>;
        },
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Action',
        accessor: 'progress',
      },
    ],
    []
  );


  return <Table columns={columns} data={sortedData} onEdit={onEdit} showDelete={true} onDelete={onDelete} />;
}

export default ProductTable;
