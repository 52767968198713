import FeatureSidebar from '../../components/FeatureSidebar';
import Footer from '../../components/Footer';

export default function BookKeeping() {
  return (
    <div>
      <div className="flex sm:h-[90vh] overflow-hidden bg-white rounded-lg">
        <div className="hidden md:flex md:flex-shrink-0">
          <FeatureSidebar />
        </div>
        <div className="flex flex-col flex-1 w-0 overflow-hidden">
          <main className="relative flex-1 focus:outline-none">
            <div className="py-6">
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 mb-4"></div>

              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8"></div>
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                <h1 className="text-lg text-black-600 font-bold">
                  {' '}
                  Bookkeeping{' '}
                </h1>

                <p>
                  The bookkeeping feature allows your business to implement
                  three main features:{' '}
                  <span className="font-bold">
                    expenses,invoices and receipts.
                  </span>
                  , Your company can now add all the expenses that your company
                  incurs, the invoices which you send out to your customers and
                  individual receipts. The invoices will be generated for you
                  and you can download it and keep it for accounting. As well as
                  having individual receipts which is useful for many functions
                  such as tax purposes.
                </p>

                <p>
                  All you need to do is create an account and start using this
                  feature today!
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
}
