import { Formik } from "formik";
import { useContext } from "react";
import * as Yup from "yup";
import Login from "../components/Login";
import { LoadingContext } from "../context/LoadingContext";
import {
  facebookLoginService,
  googleLoginService,
  loginService,
} from "../services/auth.service";
import { errorToast, successToast } from "../utils/toasts";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

export default function LoginPage() {
  const { setLoading } = useContext(LoadingContext);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const res = await loginService(values);
      successToast("login successfull");
      localStorage.setItem("token", res.data.key);
      window.location.replace("/welcome");
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      errorToast(error?.data?.non_field_errors[0]);
    }
  };

  const onFacebookSuccess = async (user) => {
    try {
      setLoading(true);
      const data = {
        access_token: user._token.accessToken,
        code: "",
        id_token: user._profile.id,
      };
      const res = await facebookLoginService(data);
      localStorage.setItem("token", res.data.key);
      window.location.replace("/welcome");
      console.log(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onFacebookFailure = () => {};

  const onGoogleSuccess = async (user) => {
    try {
      setLoading(true);
      const data = {
        access_token: user._token.accessToken,
        code: "",
        id_token: user._token.idToken,
      };
      const res = await googleLoginService(data);
      localStorage.setItem("token", res.data.key);
      window.location.replace("/welcome");
      console.log(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onGoogleFailure = (error) => {
    console.log("on google error", error);
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={LoginSchema}
      onSubmit={onSubmit}
    >
      <Login
        onFacebookFailure={onFacebookFailure}
        onFacebookSuccess={onFacebookSuccess}
        onGoogleFailure={onGoogleFailure}
        onGoogleSuccess={onGoogleSuccess}
      />
    </Formik>
  );
}
