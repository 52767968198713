import { useContext, useEffect, useState } from 'react'
import CustomModal from '../../Modal'
import SelectProduct from './SelectProduct'
import { CompanyContext } from '../../../context/CompanyContext'

const closeIcon = require('../../../assets/close_icon.png')

export default function IncomeAddForm({
  open,
  onClose,
  title,
  onAdd,
  productList,
  inventoryList,
}) {
  const { company } = useContext(CompanyContext)
  const [selectForm, setSelectForm] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [list, setList] = useState([])

  useEffect(() => {
    setList([])
  }, [open])
  const openForm = () => {
    if (inventoryList?.length === 0) {
      alert(
        'there are no products in the company inventory. You can only create income and receipts for products in the inventory.'
      )
    } else {
      setSelectForm(true)
    }
  }

  const closeSelectForm = () => {
    setSelectForm(false)
  }

  const onSelect = (values) => {
    setList((prev) => [
      ...prev,
      {
        id: values.product,
        price: parseFloat(values.price),
        quantity: values.quantity,
      },
    ])
    setSelectForm(false)
    if (values.product) {
      setErrorMessage('')
    }
  }

  const handleOnAdd = () => {
    if (list.length === 0) {
      setErrorMessage('Products are required')
    } else {
      onAdd(list)
    }
  }

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <button onClick={openForm} className="btn-primary max-w-max">
          Add Item
        </button>
        <span className="block my-2 text-base font-semibold">
          Selected Product List{' '}
        </span>
        <table className="w-full">
          <tr className="bg-gray-600 text-white">
            <th className="font-medium">Name</th>
            <th className="font-medium">Price</th>
            <th className="font-medium">Quantity</th>
          </tr>
          {list.map((item) => (
            <tr className="bg-gray-100">
              <td className="text-center">
                {productList.find((prod) => +prod.id === +item.id)?.name}
              </td>
              <td className="text-center">{`${
                company.currency_symbol || company.currency_symbol_code
              } ${item.price}`}</td>
              <td className="text-center">{item.quantity}</td>
            </tr>
          ))}
          {list.length === 0 && <span>0 item selected</span>}
        </table>
        {errorMessage && (
          <div className="text-xs mb-2 text-red-500">{errorMessage}</div>
        )}
        <div className="flex space-x-3">
          <button
            type="submit"
            onClick={handleOnAdd}
            className="btn-primary max-w-max mt-4"
          >
            Submit
          </button>
          <button onClick={onClose} className="btn-primary max-w-max mt-4">
            Cancel
          </button>
        </div>
      </div>
      <SelectProduct
        open={selectForm}
        onClose={closeSelectForm}
        productList={productList}
        inventoryList={inventoryList}
        onAdd={onSelect}
        title="Select Product"
      />
    </CustomModal>
  )
}
