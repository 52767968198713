import { useFormik } from 'formik'
import { useContext, useEffect } from 'react'
import * as Yup from 'yup'
import CustomModal from '../Modal'
import { CompanyContext } from '../../context/CompanyContext'

const closeIcon = require('../../assets/close_icon.png')

const InventoryAddSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  supplier: Yup.string().required('Required').min(5).max(300),
  quantity: Yup.number().required('Required').min(1),
  cost_price: Yup.number().required('Required').min(0),
  selling_price: Yup.number().required('Required').min(0),
  restock_level: Yup.number().required('Required').min(1),
})

export default function AddProductForm({ open, onClose, title, onAdd }) {
  const { company } = useContext(CompanyContext)
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      supplier: 'Supplier name',
      quantity: 0,
      cost_price: 0,
      selling_price: 0,
      restock_level: 0,
    },
    // enableReinitialize: true,
    validationSchema: InventoryAddSchema,
    onSubmit: (values) => {
      console.log(values)
      onAdd(values)
    },
  })

  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <label htmlFor="name">Product name</label>
          <input
            id="name"
            name="name"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.errors.name && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.name}
            </div>
          )}
          <label htmlFor="name">Description</label>
          <input
            id="description"
            name="description"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
          {formik.errors.description && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.description}
            </div>
          )}
          <label htmlFor="name">Supplier</label>
          <input
            id="supplier"
            name="supplier"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.supplier}
          />
          {formik.errors.supplier && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.supplier}
            </div>
          )}
          <label htmlFor="name">Quantity</label>
          <input
            id="quantity"
            name="quantity"
            type="number"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.quantity}
          />
          {formik.errors.quantity && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.quantity}
            </div>
          )}
          <label htmlFor="name">Cost Price</label>
          <input
            id="cost_price"
            name="cost_price"
            className="border mb-2 p-2"
            onChange={(e) =>
              formik.setFieldValue(
                'cost_price',
                e.target.value
                  .split(
                    company.currency_symbol || company.currency_symbol_code
                  )[1]
                  .trim()
              )
            }
            value={`${
              company.currency_symbol || company.currency_symbol_code
            } ${formik.values.cost_price}`}
          />
          {formik.errors.cost_price && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.cost_price}
            </div>
          )}
          <label htmlFor="name">Selling Price</label>
          <input
            id="selling_price"
            name="selling_price"
            className="border mb-2 p-2"
            onChange={(e) =>
              formik.setFieldValue(
                'selling_price',
                e.target.value
                  .split(
                    company.currency_symbol || company.currency_symbol_code
                  )[1]
                  .trim()
              )
            }
            value={`${
              company.currency_symbol || company.currency_symbol_code
            } ${formik.values.selling_price}`}
          />
          {formik.errors.selling_price && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.selling_price}
            </div>
          )}
          <label htmlFor="name">Restock Level</label>
          <input
            id="restock_level"
            name="restock_level"
            type="number"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.restock_level}
          />
          {formik.errors.restock_level && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.restock_level}
            </div>
          )}
          <div className="space-y-3">
            <button
              type="submit"
              className="btn-primary disabled:bg-gray-400"
              disabled={!(formik.isValid && formik.dirty)}
            >
              Submit
            </button>
            <button onClick={onClose} className="btn-primary">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  )
}
