import { instance } from "./base.service";

export const getCompanyListService = () => {
  return instance.get("company/");
};

export const addCompanySerivce= (data) => {
  return instance.post("company/",data)
}

export const updateCompanyService = (id, data) => {
  return instance.patch(`company/${id}/`, data);
};

export const deleteCompanyService = (id) => {
  return instance.delete(`company/${id}/`);
};
