import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.png';

export default function FeatureSidebarContent() {
  return (
    <div
          className={`flex flex-col min-h-screen h-full sm:w-64 border-r`}
        >
          <div
            className="
            flex flex-col flex-grow
            pt-5
            overflow-y-auto
            bg-white
            border-r border-gray-50
          "
          >
            <div className="flex flex-col flex-grow px-4 mt-5">
              <nav className="flex-1 space-y-1 bg-white">
                <span className="text-xl font-semibold my-2 text-gray-900">
                  Features
                </span>
                <ul>
                  <li>
                    <Link
                      to="/features/products"
                      className="
                    inline-flex
                    items-center
                    w-full
                    px-4
                    py-2
                    mt-1
                    text-base text-gray-900
                    transition
                    duration-500
                    ease-in-out
                    transform
                    rounded-lg
                    hover:underline
                    focus:shadow-outline"
                      href="#"
                    >
                      <span className="ml-4"> Products</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/features/customers"
                      className="
                    nav-link
                    hover:underline
                  "
                      href="#"
                    >
                      <span className="ml-4">Customers</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/features/inventory"
                      className="
                    nav-link
                    hover:underline
                  "
                      href="#"
                    >
                      <span className="ml-4">Inventory</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/features/bookkeeping"
                      className="
                    nav-link
                    hover:underline
                  "
                      href="#"
                    >
                      <span className="ml-4">Book Keeping</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/features/reports"
                      className="
                    nav-link
                    hover:underline
                  "
                      href="#"
                    >
                      <span className="ml-4">Reports </span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="absolute bottom-24 left-10 ">
            <a href="/welcome" className="relative">
              <img src={Logo} alt="bokia-logo" />
              <span className="absolute text-xl text-center text-primary -right-6 top-4 border border-primary rounded-full w-6 h-6">
                &trade;
              </span>
            </a>
          </div>
        </div>
  )
}
