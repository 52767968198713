import React from 'react';
import Drawer from 'react-modern-drawer';
import FeatureSidebarContent from './FeatureSidebarContent';

export default function FeatureSidebar() {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className='block sm:hidden'>
      {isOpen ? (
        <button
          className="flex text-5xl text-primary items-center cursor-pointer fixed right-10 top-6 z-50"
          onClick={() => setIsOpen(!isOpen)}
        >
          x
        </button>
      ) : (
        <svg
          onClick={() => setIsOpen(!isOpen)}
          className="fixed  z-30 flex items-center cursor-pointer right-10 top-6"
          fill="#a81f36"
          viewBox="0 0 100 80"
          width="40"
          height="40"
        >
          <rect width="100" height="10"></rect>
          <rect y="30" width="100" height="10"></rect>
          <rect y="60" width="100" height="10"></rect>
        </svg>
      )}

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        className="bla bla bla"
      >
        <FeatureSidebarContent />
      </Drawer>
      </div>
      <div className="hidden sm:block ">
        <FeatureSidebarContent />
      </div>
    </>
  );
}
