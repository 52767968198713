import React from 'react'

export default function Footer() {
  return (
    <div className='border-t bottom-0 left-0 right-0 w-full'>
        <div className='text-center py-2'>
            <span className='block text-md font-medium'>An ideal one stop platform for micro and small enterprises for book keeping and inventory activities</span>
            <span className='block text-md font-medium'>Created by experts from De Montfort University under the HEIF-funded REBOUND project</span>
            <span className='block text-md font-medium'>A book keeping and inventory application for SMEs</span>
        </div>
    </div>
  )
}
