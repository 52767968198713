import axios from 'axios'

export const BASE_URL = 'https://api.bokia.co.uk'

// export const BASE_URL = 'http://localhost:8000'

export const instance = axios.create({
  withCredentials: false,
})

instance.interceptors.request.use(
  (config) => {
    config.url = `${BASE_URL}/${config.url}`
    localStorage.getItem('token') &&
      (config.headers.common['Authorization'] = `Token ${localStorage.getItem(
        'token'
      )}`)
    return config
  },
  (error) => Promise.reject(error)
)

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      // localStorage.removeItem("token");
    }
    return Promise.reject(error.response)
  }
)
