/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../context/CompanyContext";
import { getInventoryLowAlertService } from "../services/inventory.service";
import CustomModal from "./Modal";

const StockAlert = () => {
  const [visible, setVisible] = useState(false);
  const [productList, setProductList] = useState([]);
  const { company } = useContext(CompanyContext);

  useEffect(() => {
    getLowStockAlert();
  }, []);

  const handleClose = () => {
    setVisible(false);
  };

  const getLowStockAlert = async () => {
    const res = await getInventoryLowAlertService(company?.id);
    if (res.data.length === 0) {
      setVisible(false);
    } else {
      setVisible(true);
      setProductList(res.data);
    }
  };

  return (
    <CustomModal open={visible} onClose={handleClose} title="Low Stock Alert">
      <button
        onClick={handleClose}
        className="absolute top-2 right-4 bg-gray-300 p-1"
      >
        close
      </button>
      <div className="flex justify-start items-start flex-col">
        <p className="font-bold my-4 mt-6">
          The following products need to be restocked.
        </p>
        {productList.map((product) => {
          return (
            <div className="border-b w-full p-4">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLePPCmpULvG0hvO7kzXxfimbmxZCxMq6f3w&usqp=CAU"
                className="w-10 h-10"
              />
              <p>Code: {product.code} </p>
              <p>Product: {product.name} </p>
              <p>Description: {product.description} </p>
              <p>Restock Level: {product.restock_level} </p>
              <p>Quantity Left in Stock: {product.quantity_left_in_stock} </p>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

export default StockAlert;
