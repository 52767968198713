/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormikContext } from 'formik';
import { Link } from 'react-router-dom';

import Logo from '../assets/logo.png';
import FacebookButton from './FacebookButton';
import Footer from './Footer';
import GoogleButton from './GoogleButton';

export default function Login({
  onFacebookSuccess,
  onFacebookFailure,
  onGoogleFailure,
  onGoogleSuccess,
}) {
  const {
    values,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
  } = useFormikContext();

  return (
    <div>
      <section className="w-screen h-screen bg-bg">
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <div className="flex justify-center">
                <a href="/" className="relative text-primary">
                  <img
                    src={Logo}
                    alt="logo"
                    className="w-24 h-24 object-contain"
                  />
                  <span className="absolute text-base text-center left-24 top-4 border border-primary rounded-full w-5 h-5">
                    &trade;
                  </span>
                </a>
              </div>
            </div>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md shadow-sm space-y-2">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="login-field"
                    placeholder="Email address"
                  />
                  {errors.email && (
                    <div className="text-xs mb-2 text-red-500">
                      {errors.email}
                    </div>
                  )}
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="current-password"
                    className="login-field"
                    placeholder="Password"
                  />
                  {errors.password && (
                    <div className="text-xs mb-2 text-red-500">
                      {errors.password}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div />

                <div className="text-sm">
                  <a
                    href="/forgot"
                    className="font-medium text-gray-500 hover:text-gray-400"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button type="submit" className="btn-primary">
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                  Continue
                  {isSubmitting && (
                    <svg
                      class="animate-spin ml-4 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                </button>
                <div className="flex justify-center mt-4 w-full">
                  <FacebookButton
                    provider="facebook"
                    appId="1146146422891207"
                    onLoginSuccess={onFacebookSuccess}
                    onLoginFailure={onFacebookFailure}
                  />
                  <GoogleButton
                    provider="google"
                    appId="1071312703993-u4h27jgrtldcj22ojghinr82spig677g.apps.googleusercontent.com"
                    onLoginSuccess={onGoogleSuccess}
                    onLoginFailure={onGoogleFailure}
                  />
                </div>
              </div>
            </form>
            <div className="mx-auto text-center">
              <span className="font-semibold">Create New Acocunt? </span>
              <Link to="/register" className="underline text-gray-600">
                SignUp
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="sm:absolute left-0 bottom-0 right-0 bg-white">
        <Footer />
      </div>
    </div>
  );
}
