/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import fileDownload from "js-file-download";
import { useTable, usePagination } from "react-table";
import { BASE_URL } from "../services/base.service";

export default function Table({
  columns,
  data,
  showEdit = true,
  onEdit,
  onDelete,
  dontShowButtons = false,
  showDelete = true,
  invoice = false,
  inventory = false,
  receipt = false,

}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const downloadFile = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };


  // Render the UI for your table
  return (
    <>
    <div className='tableContainer' style={{height:'505px'}}>
      <table {...getTableProps()} className="w-full">
        <thead className="bg-gray-50">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="text-left py-2 w-32"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="border-b">
                {row.cells.map((cell, index) => {
                  console.log(
                    dontShowButtons === false && index === row.cells.length - 1
                  );
                  return dontShowButtons === false &&
                    index === row.cells.length - 1 ? (
                    <td className=" py-2 mr-auto" {...cell.getCellProps()}>
                      <div className="flex space-x-2">
                        {showEdit && (
                          <button
                            className="btn-primary max-w-max"
                            onClick={() => onEdit(cell.row.original)}
                          >
                            Edit
                          </button>
                        )}
                        {!invoice && !inventory && showDelete && (
                          <button
                            className="btn-primary max-w-max"
                            onClick={() => onDelete(cell.row.original)}
                          >
                            Delete
                          </button>
                        )}
                        {receipt && (
                          <div className="flex space-x-2">
                            <a
                              rel="noreferrer"
                              className="btn-primary max-w-max"
                              target="_blank"
                              href={`${BASE_URL}/${cell.row.original.pdf_receipt}`}
                            >
                              View
                            </a>
                            <a
                              rel="noreferrer"
                              className="btn-primary max-w-max"
                              target="_blank"
                              download
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                downloadFile(
                                  `${BASE_URL}/${cell.row.original.pdf_receipt}`,
                                  "receipt"
                                )
                              }
                            >
                              Download
                            </a>

                          </div>
                        )}
                      </div>
                    </td>
                  ) : (
                    <td className="py-2" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {!data.length && <div>No Record Found</div>}
      </table>
      </div>
      {/*
          Pagination can be built however you'd like.
          This is just a very basic UI implementation:
        */}
      {!dontShowButtons && (
        <div className="pagination w-full mt-2">
          <button
            className="bg-gray-300 p-1 rounded-md cursor-pointer"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>{" "}
          <button
            className="bg-gray-300 p-1 rounded-md cursor-pointer"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
        </div>
      )}
    </>
  );
}
