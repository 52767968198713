/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import fileDownload from "js-file-download";
import { BASE_URL } from "../../services/base.service";
import ButtonLoading from "../ButtonLoading";

export default function ProductReport({ data, onSendEmail, loadingIndicator }) {

  const downloadFile = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <div className="overflow-y-auto h-[510px]">
      <div className="font-semibold text-base">Product Report</div>
      {data?.products?.map((product) => (
        <div className="my-2 border-b py-2 px-2 text-black">
          <div className="flex justify-between">
            <span>Name</span>
            <span>{product.product_name}</span>
          </div>
          <div className="flex justify-between">
            <span>Description</span>
            <span>{product.description}</span>
          </div>
          <div className="flex justify-between">
            <span>Supplier</span>
            <span>{product.supplier}</span>
          </div>
          <div className="flex justify-between">
            <span>Date</span>
            <span>{product.created_at}</span>
          </div>
          <div className="flex justify-between">
            <span>Code</span>
            <span>{product.code}</span>
          </div>
        </div>
      ))}
      <div className="flex justify-between text-primary text-base font-semibold py-2 border-b">
        <span>Total Products</span>

        <span>{data?.products?.length || 0}</span>
      </div>
      {data?.download_link && (
        <div className='flex items-center space-x-3 mt-4'>
        <button onClick={onSendEmail} className=" btn-primary flex max-w-max">
          Send Email
          {loadingIndicator && <ButtonLoading />}
        </button>
        <a rel="noreferrer" className="btn-primary max-w-max" target="_blank"
        download style={{ cursor: 'pointer' }}
        onClick={() => downloadFile(`${BASE_URL}/${data?.download_link}`, 'product_report')}>Download Report</a>
      </div>
      )}
    </div>
  );
}
