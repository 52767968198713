import { useFormik } from 'formik'
import { useEffect } from 'react'
import * as Yup from 'yup'
import CustomModal from '../Modal'

const closeIcon = require('../../assets/close_icon.png')

const InventoryUpdateSchema = Yup.object().shape({
  supplier: Yup.string().required('Required').min(5).max(20),
  quantity: Yup.number().required('Required').min(1),
  cost_price: Yup.number().required('Required').min(0),
  selling_price: Yup.number().required('Required').min(0),
  restock_level: Yup.number().required('Required').min(1),
  product: Yup.number().required('Required'),
  company: Yup.number().required('Required'),
})

export default function InventoryUpdateForm({
  data,
  open,
  onClose,
  title,
  onUpdate,
  company,
  productList,
}) {
  const formik = useFormik({
    initialValues: {
      supplier: '',
      quantity: 0,
      cost_price: 0,
      selling_price: 0,
      restock_level: 0,
      product: productList[0]?.id,
      product_name: productList[0]?.name,
      company: company.id,
    },
    enableReinitialize: true,
    validationSchema: InventoryUpdateSchema,
    onSubmit: (values, { resetForm }) => {
      console.log('yo')
      onUpdate(values, resetForm)
    },
  })

  useEffect(() => {
    if (data) {
      console.log(data)
      const { setValues } = formik
      setValues(data)
      formik.setFieldValue('company', company.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, open])
  console.log(formik.errors)
  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <label htmlFor="name">Supplier</label>
          <input
            id="supplier"
            name="supplier"
            type="text"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.supplier}
          />
          {formik.errors.supplier && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.supplier}
            </div>
          )}
          <label htmlFor="name">Quantity</label>
          <input
            id="quantity"
            name="quantity"
            type="number"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.quantity}
          />
          {formik.errors.quantity && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.quantity}
            </div>
          )}
          <label htmlFor="name">Cost Price</label>
          <input
            id="cost_price"
            name="cost_price"
            className="border mb-2 p-2"
            onChange={(e) =>
              formik.setFieldValue(
                'cost_price',
                e.target.value
                  .split(
                    company.currency_symbol || company.currency_symbol_code
                  )[1]
                  .trim()
              )
            }
            value={`${
              company.currency_symbol || company.currency_symbol_code
            } ${formik.values.cost_price}`}
          />
          {formik.errors.cost_price && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.cost_price}
            </div>
          )}
          <label htmlFor="name">Selling Price</label>
          <input
            id="selling_price"
            name="selling_price"
            className="border mb-2 p-2"
            onChange={(e) =>
              formik.setFieldValue(
                'selling_price',
                e.target.value
                  .split(
                    company.currency_symbol || company.currency_symbol_code
                  )[1]
                  .trim()
              )
            }
            value={`${
              company.currency_symbol || company.currency_symbol_code
            } ${formik.values.selling_price}`}
          />
          {formik.errors.selling_price && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.selling_price}
            </div>
          )}
          <label htmlFor="name">Restock Level</label>
          <input
            id="restock_level"
            name="restock_level"
            type="number"
            className="border mb-2 p-2"
            onChange={formik.handleChange}
            value={formik.values.restock_level}
          />
          {formik.errors.restock_level && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.restock_level}
            </div>
          )}
          <label htmlFor="product">Product</label>
          <select
            id="product"
            name="product"
            value={formik.values.product}
            onChange={formik.handleChange}
            className="border mb-2 p-2"
          >
            {productList?.map((product) => (
              <option value={product.id}>{product.name}</option>
            ))}
          </select>
          {formik.errors.product && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.product}
            </div>
          )}
          <label htmlFor="company">Company</label>
          <input className="border mb-2 p-2" value={company.name} />
          <div className="space-y-3">
            <button type="submit" className="btn-primary">
              Submit
            </button>
            <button onClick={onClose} className="btn-primary">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </CustomModal>
  )
}
