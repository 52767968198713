import { instance } from "./base.service";

export const inventory_bulk_upload = (data) => {
  return instance.post(`inventory/company/bulk_inventory_upload/`,data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const inventoryListService = (id) => {
  return instance.get(`inventory/company/${id}/`);
};

export const inventoryAddService = (data) => {
  return instance.post("inventory/", data);
};

export const inventoryUpdateService = (id, data) => {
  return instance.patch(`inventory/${id}/`, data);
};

export const inventoryDeleteService = (id) => {
  return instance.delete(`inventory/${id}/`);
};

export const getInventoryReport = (url) => {
  return instance.get(`inventory/inventory-report/${url}/`);
};

export const getInventoryLowAlertService = (id) => {
  return instance.get(`product/${id}/inventory/alert/`);
};

export const getCompanyProductService = (id) => {
  return instance.get(`/product/company/${id}/`);
};
