import { useState, useEffect, useContext } from 'react'
import CustomModal from '../Modal'
import AddProductForm from './AddProjectModal'
import Html5QrcodePlugin from './Html5QrcodePlugin'
import {
  productAddService,
  productsListService,
} from '../../services/products.service'
import { CompanyContext } from '../../context/CompanyContext'
import { LoadingContext } from '../../context/LoadingContext'
import { inventoryAddService } from '../../services/inventory.service'
import { successToast } from '../../utils/toasts'

const closeIcon = require('../../assets/close_icon.png')

export default function ScanWithCamera({ open, onClose, onSuccessScan }) {
  const { company } = useContext(CompanyContext)
  const { setLoading } = useContext(LoadingContext)
  const [productList, setProductList] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [scannedBarcode, setScannedBarcode] = useState('')

  useEffect(() => {
    fetchProducts()
  }, [])

  async function fetchProducts() {
    const response = await productsListService(company?.id)
    setProductList(response.data || [])
  }

  const processBarcodeData = async (data) => {
    console.log('Scanned data:', data)
    const response = await productsListService(company?.id)

    //fetch product
    const product = response?.data?.find((product) => product?.code === data)
    if (product) {
      // successToast('product added')
      onSuccessScan(product)
      setScannedBarcode('')
      onClose()
    }
    if (!product) {
      setScannedBarcode(data)
      setShowMessage(true)
    }
    // Process the data as needed, such as saving it to state or sending it to an API
  }

  const handleOnAdd = async (values) => {
    const {
      name,
      description,
      supplier,
      quantity,
      cost_price,
      selling_price,
      restock_level,
    } = values

    setLoading(true)
    // create product
    const productResponse = await productAddService({
      name,
      description,
      company: company?.id,
      code: scannedBarcode,
    })

    setProductList((prev) => [...prev, productResponse.data])

    // create inventory
    await inventoryAddService({
      supplier,
      quantity,
      cost_price,
      selling_price,
      restock_level,
      product: productResponse.data?.id,
      company: company?.id,
    })

    onSuccessScan(productResponse.data)
    setShowCreateForm(false)
    setShowMessage(false)
    onClose()
    setLoading(false)
    setScannedBarcode('')
  }

  const onNewScanResult = (decodedText, decodedResult) => {
    processBarcodeData(decodedText)
  }

  return (
    <CustomModal open={open} onClose={onClose} title={''}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{''}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />

        <CustomModal
          open={showMessage}
          onClose={() => {
            setScannedBarcode('')
            setShowMessage(false)
          }}
          title={''}
        >
          <div className="w-[70vw] sm:w-[50vw]">
            <div className="flex justify-between items-center w-full">
              <h1 className="font-bold text-lg text-black-600"></h1>
              <img
                className="w-6 h-6 cursor-pointer"
                alt="close-btn"
                src={closeIcon}
                onClick={() => setShowMessage(false)}
              />
            </div>
            <div className="flex flex-col justify-center items-center space-y-2">
              <p>
                the scanned product doesn't exists in system please click Create
                to create the product
              </p>

              <div className="flex space-x-2">
                <div
                  onClick={() => setShowMessage(false)}
                  className="btn-primary bg-gray-400 hover:bg-gray-500 btn- max-w-max cursor-pointer"
                >
                  Cancel
                </div>
                <div
                  onClick={() => setShowCreateForm(true)}
                  className="btn-primary max-w-max cursor-pointer"
                >
                  Create
                </div>
              </div>
            </div>
          </div>
        </CustomModal>

        <AddProductForm
          onAdd={handleOnAdd}
          onClose={() => setShowCreateForm(false)}
          open={showCreateForm}
          title={''}
          key={'product_create_form'}
        />
      </div>
    </CustomModal>
  )
}
