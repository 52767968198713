import { useContext, useEffect, useState } from "react";
import AddInvoice from "../components/bookkeeping/invoice/AddInvoice";
import { CompanyContext } from "../context/CompanyContext";
import SidebarLayout from "../layout/SidebarLayout";
import { 
  customerListService,
  } from "../services/customer.service";

import {
  addInvoiceService,
  deleteInvoiceService,
  listInvoiceService,
} from "../services/invoice.service";
import { productsListService } from "../services/products.service";
import Invoice from "../components/bookkeeping/invoice/Invoice";
import { LoadingContext } from "../context/LoadingContext";
import {inventoryListService} from "../services/inventory.service";
import { errorToast } from "../utils/toasts";

export default function InvoicePage() {
  const { company } = useContext(CompanyContext);
  const { setLoading } = useContext(LoadingContext);
  const [addForm, setAddForm] = useState(false);
  const [productList, setProductList] = useState([]);
  const [listInvoice, setListInvoice] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);

  useEffect(() => {
    fetchProducts();
    fetchCustomers();
    fetchInvoice();
    fetchInventory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchProducts() {
    try {
      setLoading(true);
      const res = await productsListService(company?.id);
      setProductList(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function fetchCustomers() {
    try {
      setLoading(true);
      const res = await customerListService(company?.id);
      setCustomerList(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function fetchInventory() {
    try {
      setLoading(true);
      const res = await inventoryListService(company?.id);
      setInventoryList(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function fetchInvoice() {
    try {
      setLoading(true);
      const res = await listInvoiceService(company?.id);
      console.log('fetch invoice',res.data)
      const sorted = res.data.sort(function(a, b) {
        var keyA = new Date(a.updated_at),
          keyB = new Date(b.updated_at);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      setListInvoice(sorted);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onAddClick = () => {
    setAddForm(true);
  };

  const closeAddForm = () => {
    setAddForm(false);
  };

  const onAdd = async (data) => {
    try {
      setLoading(true);
      await addInvoiceService(data);
      await fetchInvoice();
      closeAddForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorToast(error?.data?.error)
    }
  };


  const onDelete = async ({ id }) => {
    try {
      setLoading(true);
      await deleteInvoiceService(id);
      await fetchInvoice();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <SidebarLayout title="Invoices">
      <Invoice
        onAddClick={onAddClick}
        data={listInvoice}
        onEdit={() => {}}
        onDelete={onDelete}
      />
      <AddInvoice
        company={company}
        open={addForm}
        onClose={closeAddForm}
        title="Add New Invoice"
        onAdd={onAdd}
        productList={productList}
        inventoryList={inventoryList}
      />
    </SidebarLayout>
  );
}
