import { instance } from './base.service';

export const customerListService = (id) => {
  return instance.get(`customer/company/${id}/`);
};

export const customerAddService = (data) => {
  return instance.post('customer/', data);
};

export const customerUpdateService = (id, data) => {
  return instance.patch(`customer/${id}/`, data);
};

export const customerDeleteService = (id) => {
  return instance.delete(`customer/${id}/`);
};

export const getCustomerReport = (url) => {
  return instance.get(`customer/customers-report/${url}/false/`);
};
