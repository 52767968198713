import CheckoutTable from './CheckoutTable'

export default function Checkout({ onAddClick, data, onEdit, onDelete }) {
  return (
    <div className="mt-10">
      <button onClick={onAddClick} className="btn-primary max-w-max mb-2">
        Scan products
      </button>
      <CheckoutTable data={data} onDelete={onDelete} onEdit={onEdit} />
    </div>
  )
}
