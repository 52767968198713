import FeatureSidebar from '../../components/FeatureSidebar';
import Footer from '../../components/Footer';

export default function Products() {
  return (
    <div>
      <div className="flex sm:h-[90vh] overflow-hidden bg-white rounded-lg">
        <div className="hidden md:flex md:flex-shrink-0">
          <FeatureSidebar />
        </div>
        <div className="flex flex-col flex-1 w-0 overflow-hidden">
          <main className="relative flex-1 focus:outline-none">
            <div className="py-6">
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 mb-4"></div>

              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8"></div>
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                <h1 className="text-lg text-black-600 font-bold"> Products</h1>

                <p>
                  Using the Bokia app personal assistant, your company will be
                  able to add and delete as many products as you wish all by the
                  click of a button. This sophisticated feature will give your
                  company the ability to see the different types of products
                  that you use on a regular basis. Thus, allowing your company
                  to have a visual representation of the different products that
                  you stock. Your company can easily edit and delete your
                  products and see all the products in one place.
                </p>
                <p>
                  All you need to do is create an account and start using this
                  feature today!{' '}
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
}
