/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import fileDownload from 'js-file-download'
import { BASE_URL } from '../../services/base.service'
import ButtonLoading from '../ButtonLoading'
import { useContext } from 'react'
import { CompanyContext } from '../../context/CompanyContext'

export default function ProfitLossReport({
  data,
  loadingIndicator,
  onSendEmail,
}) {
  const { company } = useContext(CompanyContext)

  const downloadFile = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }
  return (
    <div className="mt-6 overflow-y-auto h-[510px]">
      <div className="font-semibold text-base">Income</div>
      {data.products?.map((product) => (
        <div className="flex justify-between border-b py-2 px-2">
          <span>{product.name}</span>
          <span>
            {company.currency_symbol || company.currency_symbol_code}{' '}
            {product.total_amount.toFixed(2)}
          </span>
        </div>
      ))}
      <div className="flex justify-between text-primary text-base font-semibold py-2 border-b">
        <span>Total Income</span>
        <span>
          {company.currency_symbol || company.currency_symbol_code}{' '}
          {data?.data?.total_income.toFixed(2) || 0}
        </span>
      </div>

      {/** COST OF PRODUCTS */}
      <div className="my-4 font-semibold text-base">Cost of Products</div>
      {data.products?.map((product) => {
        return (
          <div className="flex justify-between border-b py-2 px-2">
            <span>{product.name}</span>
            <span>
              {company.currency_symbol || company.currency_symbol_code}{' '}
              {product.total_cost.toFixed(2) || 0.0}
            </span>
          </div>
        )
      })}
      <div className="flex justify-between text-primary text-base font-semibold py-2 border-b">
        <span>Cost of Products Sold</span>
        <span>
          {company.currency_symbol || company.currency_symbol_code}{' '}
          {data?.data?.total_cost.toFixed(2) || 0}
        </span>
      </div>
      <div className="flex justify-between text-primary text-base font-semibold py-2 border-b">
        <span>Gross Profit (Loss) </span>
        <span>
          {company.currency_symbol || company.currency_symbol_code}{' '}
          {(data?.data?.total_income - data?.data?.total_cost).toFixed(2) || 0}
        </span>
      </div>

      {/** EXPENSES */}
      <div className="my-4 font-semibold text-base">Expenses</div>
      <div className="flex justify-between text-primary text-base font-semibold py-2 border-b">
        <span>Total Expenses</span>
        <span>
          {company.currency_symbol || company.currency_symbol_code}{' '}
          {data?.data?.total_expense.toFixed(2) || 0}
        </span>
      </div>
      <div className="flex justify-between text-primary text-base font-semibold py-2 border-b">
        <span>Net Profit (Loss)</span>
        <span>
          {company.currency_symbol || company.currency_symbol_code}{' '}
          {(
            data?.data?.total_income -
            data?.data?.total_cost -
            data?.data?.total_expense
          ).toFixed(2) || 0}
        </span>
      </div>
      {data?.data && (
        <div className="flex items-center space-x-3 mt-4">
          <button onClick={onSendEmail} className=" btn-primary flex max-w-max">
            Send Email
            {loadingIndicator && <ButtonLoading />}
          </button>
          <a
            rel="noreferrer"
            className="btn-primary max-w-max"
            target="_blank"
            download
            style={{ cursor: 'pointer' }}
            onClick={() =>
              downloadFile(
                `${BASE_URL}/${data?.data?.download_link}`,
                'profit_and_loss_report'
              )
            }
          >
            Download Report
          </a>
        </div>
      )}
    </div>
  )
}
