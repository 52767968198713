import FeatureSidebar from '../../components/FeatureSidebar';
import Footer from '../../components/Footer';

export default function Inventory() {
  return (
    <div>
      <div className="flex sm:h-[90vh] overflow-hidden bg-white rounded-lg">
        <div className="hidden md:flex md:flex-shrink-0">
          <FeatureSidebar />
        </div>
        <div className="flex flex-col flex-1 w-0 overflow-hidden">
          <main className="relative flex-1 focus:outline-none">
            <div className="py-6">
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 mb-4"></div>

              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8"></div>
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                <h1 className="text-lg text-black-600 font-bold">
                  {' '}
                  Inventory{' '}
                </h1>

                <p>
                  Perhaps one of the most revolutionary aspects of this app, is
                  the ability to now keep an updated inventory stock list. With
                  this feature, you will be able to add an item by including
                  the:
                </p>
                <ul className='ml-4 my-2 list-disc'>
                  <li>Product name </li>
                  <li>Supplier </li>
                  <li>Quantity </li>
                  <li>Selling Price </li>
                  <li>Cost Price </li>
                  <li>Restock Level</li>
                </ul>
                <p>
                  You can download the inventory list as an Excel file which
                  will be tremendously helpful with your company’s accounting
                  and bookkeeping. You can even export this inventory list to
                  your PC and start sharing it.
                </p>
                <p>
                  Start using this powerful feature by simply registering with
                  the app!
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
}
