import { useContext, useState } from "react";
import { CompanyContext } from "../context/CompanyContext";
import SidebarLayout from "../layout/SidebarLayout";
import ReportFilter from "../components/ReportFilter";
import { getProductReport } from "../services/products.service";
import ProductReport from "../components/reports/ProductReport";
import { LoadingContext } from "../context/LoadingContext";
import { errorToast, successToast } from "../utils/toasts";

export default function ProductReportPage() {
  const { company } = useContext(CompanyContext);
  const { setLoading } = useContext(LoadingContext);

  const [data, setData] = useState({});
  const [ loadingIndicator, setLoadingIndicator ] = useState(false)
  const [startDate,setStartDate] = useState(new Date())
  const [endDate,setEndDate] = useState(new Date())

  const onSendEmail = async () => {
    try {
      setLoadingIndicator(true);
      const res = await getProductReport(
        `${company?.id}/true/${startDate}/${endDate}`
      );
      setLoadingIndicator(false);
      successToast("E-mail report sent successfully");
    } catch (error) {
      errorToast("something went wrong");
    } finally {
      setLoadingIndicator(false)
    }
  };

  const onFilter = async ({ startDate, endDate }, setSubmitting) => {
    try {
      setLoading(true);
      setSubmitting(true);
      const res = await getProductReport(
        `${company?.id}/false/${startDate}/${endDate}`
      );
      setSubmitting(false);
      setStartDate(startDate)
      setEndDate(endDate)
      console.log(res);
      setData(res.data[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <SidebarLayout title="Product Report">
      <ReportFilter onSearch={onFilter} />
      <ProductReport data={data} loadingIndicator={loadingIndicator} onSendEmail={onSendEmail} />
    </SidebarLayout>
  );
}
