import { useEffect, useState } from 'react'
import CustomModal from '../Modal'
import ScanProduct from './ScanProduct'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { inventoryListService } from '../../services/inventory.service'
import SelectManualProduct from './SelectManualProduct'
import ScanWithCamera from './ScanWithCamera'

const closeIcon = require('../../assets/close_icon.png')

const ReceiptSchema = Yup.object().shape({
  products: Yup.array().min(1, 'Products are required').required('Required'),
  company: Yup.number().required('Required'),
})

export default function ScanProductForm({
  open,
  onClose,
  title,
  onAdd,
  productList,
  inventoryList,
  company,
}) {
  const [selectForm, setSelectForm] = useState(false)
  const [manualForm, setManualForm] = useState(false)
  const [scanWithCamera, setScanWithCamera] = useState(false)
  const [idNameMap, setIdNameMap] = useState({})
  const [initialValue, setInitialValue] = useState({
    quantity: 0,
    price: inventoryList[0]?.selling_price,
    product: (inventoryList?.filter((x) => x.quantity > 0))[0]?.product,
  })

  const formik = useFormik({
    initialValues: {
      products: [],
      company: company.id,
      total_receipt_amount: 0,
    },
    // enableReinitialize: true,
    validationSchema: ReceiptSchema,
    onSubmit: (values, { resetForm }) => {
      onAdd(values)
      // resetForm();
    },
  })

  useEffect(() => {
    setInitialValue({
      quantity: 0,
      price: inventoryList[0]?.selling_price,
      product: (inventoryList?.filter((x) => x.quantity > 0))[0]?.product,
    })
  }, [inventoryList])

  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const openForm = () => {
    if (inventoryList?.length === 0) {
      alert(
        'there are no products in the company inventory. You can only create income and receipts for products in the inventory.'
      )
      setSelectForm(true)
    } else {
      setSelectForm(true)
    }
  }

  const closeSelectForm = () => {
    setSelectForm(false)
  }

  useEffect(() => {
    if (productList.length) {
      for (let i = 0; i < productList.length; i++) {
        idNameMap[productList[i].id] = productList[i].name
        setIdNameMap(idNameMap)
      }
    }
  }, [productList])

  const onScanSelect = (values) => {
    console.log(values)
    let isItemExists = formik.values.products.find(
      (item) => +item.id === +values.product
    )
    if (!isItemExists) {
      formik.setFieldValue('products', [
        ...formik.values.products,
        {
          id: values.product,
          price: parseFloat(values.selling_price),
          quantity: 1,
        },
      ])
      idNameMap[values.product] = values.product_name
      setIdNameMap(idNameMap)
    } else {
      // update the item
      formik.setFieldValue(
        'products',
        formik.values.products.map((item) => {
          return +item.id === +values.product
            ? {
                id: values.product,
                price: parseFloat(item.price),
                quantity: item.quantity + 1,
              }
            : item
        })
      )
    }

    setSelectForm(false)
    setManualForm(false)
  }

  const onSelect = (values) => {
    console.log(values)
    let isItemExists = formik.values.products.find(
      (item) => +item.id === +values.product
    )
    if (!isItemExists) {
      formik.setFieldValue('products', [
        ...formik.values.products,
        {
          id: values.product,
          price: parseFloat(values.selling_price),
          quantity: 1,
        },
      ])
      idNameMap[values.product] = values.product_name
      setIdNameMap(idNameMap)
    } else {
      // update the item
      formik.setFieldValue(
        'products',
        formik.values.products.map((item) => {
          return +item.id === +values.product
            ? {
                id: values.product,
                price: parseFloat(values.selling_price),
                quantity: item.quantity + 1,
              }
            : item
        })
      )
    }

    setSelectForm(false)
    setManualForm(false)
  }

  const handleManualEntry = async (selectedProduct) => {
    const res = await inventoryListService(company?.id)
    const prodt = res.data?.find(
      (ivp) => ivp?.product === parseInt(selectedProduct.product)
    )

    let isItemExists = formik.values.products.find(
      (item) => +item.id === +prodt.product
    )

    if (!isItemExists) {
      formik.setFieldValue('products', [
        ...formik.values.products,
        {
          id: prodt.product,
          price: parseFloat(selectedProduct.selling_price),
          quantity: selectedProduct.quantity,
        },
      ])
      idNameMap[prodt.product] = prodt.product_name
      setIdNameMap(idNameMap)
    } else {
      // update the item
      formik.setFieldValue(
        'products',
        formik.values.products.map((item) => {
          return +item.id === +prodt.product
            ? {
                id: prodt.product,
                price: parseFloat(selectedProduct.selling_price),
                quantity: selectedProduct.quantity,
              }
            : item
        })
      )
    }
    setSelectForm(false)
    setManualForm(false)
  }

  const onTableRowClick = (item) => {
    setInitialValue({
      quantity: item.quantity,
      price: item.price,
      product: item.id,
    })
    setManualForm(true)
  }

  const handleScannedProduct = async (product) => {
    const res = await inventoryListService(company?.id)
    const prodt = res.data?.find((ivp) => ivp?.product === product.id)
    onScanSelect(prodt)
  }

  const handleUpdateProduct = async (product) => {
    const res = await inventoryListService(company?.id)
    const prodt = res.data?.find((ivp) => ivp?.product === product.id)
    onSelect(prodt)
  }

  return (
    <CustomModal open={open} onClose={onClose} title={title}>
      <div className="w-[70vw] sm:w-[50vw]">
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-lg text-black-600">{title}</h1>
          <img
            className="w-6 h-6 cursor-pointer"
            alt="close-btn"
            src={closeIcon}
            onClick={onClose}
          />
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col">
          <div className="flex space-x-2">
            <div
              onClick={openForm}
              className="btn-primary max-w-max cursor-pointer"
            >
              Scan item
            </div>
            <div
              onClick={() => setScanWithCamera(true)}
              className="btn-primary max-w-max cursor-pointer"
            >
              Scan item with camera
            </div>
            <div
              onClick={() => {
                setManualForm(true)
              }}
              className="btn-primary max-w-max cursor-pointer"
            >
              Manual item entry
            </div>
          </div>
          <span className="block my-2 text-base font-semibold">
            Selected Product List{' '}
          </span>
          <table className="w-full">
            <tr className="bg-gray-600 text-white">
              <th className="font-medium">Name</th>
              <th className="font-medium">Price</th>
              <th className="font-medium">Quantity</th>
              <th className="font-medium">Action</th>
            </tr>
            {formik.values.products.map((item) => (
              <tr className="bg-gray-100" onClick={() => onTableRowClick(item)}>
                <td className="text-center">
                  {/*productList.find((prod) => prod.id === item.id).name*/}
                  {idNameMap[item.id]}
                </td>
                <td className="text-center">{`${
                  company.currency_symbol || company.currency_symbol_code
                } ${item.price}`}</td>
                <td className="text-center">{item.quantity}</td>
                <td className="flex justify-center cursor-pointer">
                  <span onClick={() => onTableRowClick(item)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-center"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      />
                    </svg>
                  </span>
                </td>
              </tr>
            ))}
            {formik.values.products.length === 0 && (
              <span>0 item selected</span>
            )}
          </table>
          {formik.errors.products && (
            <div className="text-xs mb-2 text-red-500">
              {formik.errors.products}
            </div>
          )}
          <div className="flex space-x-3">
            <button
              type="submit"
              className="btn-primary max-w-max mt-4 disabled:bg-gray-400"
              disabled={!(formik.isValid && formik.dirty)}
            >
              Checkout
            </button>
            <button onClick={onClose} className="btn-primary max-w-max mt-4">
              Cancel
            </button>
          </div>
        </form>
      </div>

      {scanWithCamera && (
        <ScanWithCamera
          onClose={() => setScanWithCamera(false)}
          onSuccessScan={handleScannedProduct}
          open={scanWithCamera}
          key={'scan_with_camera'}
        />
      )}
      {selectForm && (
        <ScanProduct
          initialValue={initialValue}
          open={selectForm}
          onClose={closeSelectForm}
          onSuccessScan={handleUpdateProduct}
        />
      )}

      {manualForm && (
        <SelectManualProduct
          initialValue={initialValue}
          open={manualForm}
          onClose={() => setManualForm(false)}
          productList={productList}
          inventoryList={inventoryList}
          onAdd={handleManualEntry}
          title="Select Product"
        />
      )}
    </CustomModal>
  )
}
