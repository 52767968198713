import { Link } from "react-router-dom";

export default function BookKeeping({ data }) {
  return (
    <div className="w-full flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-10">
      {data.map((page, index) => (
        <Link
          to={page.url}
          className="relative block p-8 border-4 border-primary shadow-xl rounded-xl w-full max-w-sm"
        >
          <div className="mt-4 text-gray-500 sm:pr-8">
            {index === 2 ? (
              <svg
                class="h-8 w-8 text-black"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <path d="M14 3v4a1 1 0 0 0 1 1h4" />{" "}
                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />{" "}
                <line x1="9" y1="7" x2="10" y2="7" />{" "}
                <line x1="9" y1="13" x2="15" y2="13" />{" "}
                <line x1="13" y1="17" x2="15" y2="17" />
              </svg>
            ) : (
              <svg
                class="h-8 w-8 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            )}

            <h5 className="mt-4 text-xl font-bold text-gray-900">
              {page.title}
            </h5>
          </div>
        </Link>
      ))}
    </div>
  );
}
