import React from 'react'
import { toast } from 'react-toastify'
import 'tailwindcss/tailwind.css'
import { deleteUserService } from '../services/auth.service'

export default function AccountDeletePage() {
  const handleDeleteAccount = async () => {
    console.log('here is ', localStorage.getItem('token'))
    if (localStorage.getItem('token') !== null) {
      await deleteUserService()
      toast.success('Account deleted')
      localStorage.clear()
      window.location.replace('/')
    } else {
      toast.warn('You need to be logged in to perform this action ')
    }
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-xl">
        <h1 className="text-2xl font-bold mb-6">Delete Account</h1>

        <p className="mb-6">
          Are you sure you want to delete your account permanently? Press
          "Delete account" to remove it permanently, or "Cancel" if you want to
          keep your benefits.
        </p>

        <button
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md mr-4"
          onClick={handleDeleteAccount}
        >
          Delete account
        </button>
        <button
          className="bg-gray-200 hover:bg-gray-300 text-black px-4 py-2 rounded-md mr-4"
          onClick={() => {
            window.location.replace('/')
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}
