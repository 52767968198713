import { Formik } from "formik";
import { useContext } from "react";
import * as Yup from "yup";
import ChangePassword from "../components/auth/ChangePassword";
import { LoadingContext } from "../context/LoadingContext";
import { changePasswordService } from "../services/auth.service";
import { errorToast, successToast } from "../utils/toasts";

const PasswordChangeSchema = Yup.object().shape({
  old_password: Yup.string().required("Required"),
  new_password1: Yup.string().required("Required"),
  new_password2: Yup.string().oneOf(
    [Yup.ref("new_password1"), null],
    "Passwords must match"
  ),
});

export default function PasswordChangePage() {
  const { setLoading } = useContext(LoadingContext);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      await changePasswordService(values);
      successToast("Password Changed Successfully");
      setSubmitting(false);
      setLoading(false);
      window.location.replace("/welcome");
    } catch (error) {
      setLoading(false);
      errorToast(error.request.response);
    }
  };

  return (
    <Formik
      initialValues={{ old_password: "", new_password1: "", new_password2: "" }}
      validationSchema={PasswordChangeSchema}
      onSubmit={onSubmit}
    >
      <ChangePassword />
    </Formik>
  );
}
