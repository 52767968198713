import FeatureSidebar from '../../components/FeatureSidebar';
import Footer from '../../components/Footer';

export default function Reports() {
  return (
    <div>
      <div className="flex sm:h-[90vh] overflow-hidden bg-white rounded-lg">
        <div className="hidden md:flex md:flex-shrink-0">
          <FeatureSidebar />
        </div>
        <div className="flex flex-col flex-1 w-0 overflow-hidden">
          <main className="relative flex-1 focus:outline-none">
            <div className="py-6">
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 mb-4"></div>

              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8"></div>
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                <h1 className="text-lg text-black-600 font-bold"> Reports </h1>

                <p>
                  From the data that your organisation has generated there will
                  be a series od reports that will be generated such as:
                </p>
                <ul className='ml-4 my-2 list-disc'>
                  <li>
                    <span className='font-bold'>Sales</span> report (shows total sales for a given
                    time period){' '}
                  </li>
                  <li>
                    <span className='font-bold'>Profit/Loss</span> report (shows total profit or
                    losses made for any given time period){' '}
                  </li>
                  <li>
                    <span className='font-bold'>Inventory</span> Report (shows total inventory amount
                    for any given time period){' '}
                  </li>
                  <li>
                    <span className='font-bold'>Product</span> Report (shows total products in stock
                    for any given time period){' '}
                  </li>
                  <li>
                    <span className='font-bold'>Customer</span> Report (shows the total customers your
                    company interacts with at any given time){' '}
                  </li>
                </ul>
                <p>
                  This is particularly useful for your company because you can
                  track how well your organisation is doing at any time.
                </p>
                <p>Thank you!</p>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
}
