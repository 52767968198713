import { instance } from './base.service';

export const productsListService = (id) => {
  return instance.get(`product/company/${id}/`);
};

export const productFetchService = (id) => {
  return instance.get(`product/${id}/`);
};

export const productAddService = (data) => {
  return instance.post('product/', data);
};

export const productUpdateService = (id, data) => {
  return instance.patch(`product/${id}/`, data);
};

export const productDeleteService = (id) => {
  return instance.delete(`product/${id}/`);
};

export const getProductReport = (url) => {
  return instance.get(`product/products-report/${url}/false/`);
};
