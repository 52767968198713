import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';
import Footer from '../components/Footer';
import SidebarContent from '../components/SidebarContent';
import { CompanyContext } from '../context/CompanyContext';
import Drawer from 'react-modern-drawer';

export default function SidebarLayout({ title, children }) {
  const { company } = useContext(CompanyContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div>
      <div className="flex sm:h-[100vh]  bg-white rounded-lg">
        <div className="block sm:hidden">
          {isOpen ? (
            <button
              className="flex text-5xl text-primary items-center cursor-pointer fixed right-10 top-6 z-50"
              onClick={() => setIsOpen(!isOpen)}
            >
              x
            </button>
          ) : (
            <svg
              onClick={() => setIsOpen(!isOpen)}
              className="fixed  z-30 flex items-center cursor-pointer right-10 top-6 bg-white"
              fill="#a81f36"
              viewBox="0 0 100 80"
              width="40"
              height="40"
            >
              <rect width="100" height="10"></rect>
              <rect y="30" width="100" height="10"></rect>
              <rect y="60" width="100" height="10"></rect>
            </svg>
          )}

          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction="left"
            className="bla bla bla"
          >
            <SidebarContent />
          </Drawer>
        </div>
        <div className="hidden md:flex md:flex-shrink-0">
          <SidebarContent />
        </div>
        <div className="flex flex-col flex-1 w-0 overflow-hidden">
          <main className="relative flex-1 focus:outline-none">
            <div className="py-6">
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 mb-4 flex justify-between items-center">
                <span className="text-lg text-primary font-extrabold">
                  {company?.name}
                </span>
                <a href='/welcome' className="text-lg text-primary font-extrabold flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span>User Profile</span>
                </a>
              </div>

              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                <h1 className="text-lg text-black-600 font-bold"> {title}</h1>
              </div>
              <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 sm:h-[620px] overflow-y-auto">
                {children}
                {/* Do not cross the closing tag underneath this coment*/}
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="sm:absolute left-0 bottom-0 right-0 bg-white">
        <Footer />
      </div>
    </div>
  );
}
