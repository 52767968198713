import Reports from "../components/reports/Reports";
import SidebarLayout from "../layout/SidebarLayout";

const data = [
  {
    title: "Sales Report",
    url: "/reports/sales",
  },
  {
    title: "Profit/Loss Report",
    url: "/reports/profit-loss",
  },
  {
    title: "Inventory Report",
    url: "/reports/inventory",
  },
  {
    title: "Product Report",
    url: "/reports/product",
  },
  {
    title: "Customer Report",
    url: "/reports/customer",
  },
];

export default function ReportsPage() {
  return (
    <SidebarLayout title="Reports">
      <Reports data={data} />
    </SidebarLayout>
  );
}
