import { instance } from './base.service';

export const addExpenseService = (data) => {
  return instance.post('expense/', data);
};

export const listExpenseService = (id) => {
  return instance.get(`expense/company/${id}/`);
};

export const deleteExpenseService = (id) => {
  return instance.delete(`expense/${id}/`);
};
