import { Link } from "react-router-dom";

export default function Reports({ data }) {
  return (
    <div className="w-full flex flex-wrap mt-10">
      {data.map((page) => (
        <Link
          to={page.url}
          className="relative block p-8 m-1 border-4 border-primary shadow-xl rounded-xl w-full max-w-sm"
        >
          <div className="mt-4 text-gray-500 sm:pr-8">
            <svg
              class="h-8 w-8 text-black"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>

            <h5 className="mt-4 text-xl font-bold text-gray-900">
              {page.title}
            </h5>
          </div>
        </Link>
      ))}
    </div>
  );
}
