import { instance } from './base.service';

export const addIncomeService = (data) => {
  return instance.post('income/', data);
};

export const listIncomeService = (id) => {
  return instance.get(`income/company/${id}/`);
};

export const deleteIncomeService = (id) => {
  return instance.delete(`income/${id}/`);
};

export const getSaleReport = (url) => {
  return instance.get(`income/sales-report/${url}/`);
};

export const getProfitLossReport = (url) => {
  return instance.get(`income/profit-loss/${url}/`);
};
