import CompanyIcon from '../assets/company_icon.png';
import PrivacyIcon from '../assets/privacy_policy_icon.png';
import PasswordIcon from '../assets/change-password-icon.png';
import LogoutIcon from '../assets/logout-icon.webp';
import UserManual from '../assets/user_manual_icon.jpeg';
import DeleteIcon from '../assets/delete-account.jpeg';
import { Link } from 'react-router-dom';
import FeatureSidebar from './FeatureSidebar';
import Footer from '../components/Footer';
import {deleteUserService} from '../services/auth.service'

export default function Welcome() {


  return (
    <div>
      <section className="w-screen min-h-screen bg-bg flex justify-center pt-20 sm:pt-0 bg-transparent sm:items-center">
        <div className="flex flex-wrap items-center justify-center">
          <FeatureSidebar />
        </div>
        <div className="flex flex-wrap items-center justify-center">
          <Link to="/companies">
            <div className="p-4 w-64 h-64">
              <div className="rounded-card">
                <img
                  className="mx-auto w-24 h-24 object-cover"
                  src={CompanyIcon}
                  alt="company"
                />
                <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">
                  Company
                </h1>
              </div>
            </div>
          </Link>
          <Link to="/privacy-policy" className="p-4 w-64 h-64">
            <div className="rounded-card">
              <img
                className="mx-auto w-24 h-24 object-cover"
                src={PrivacyIcon}
                alt="privacy"
              />
              <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">
                Privacy Policy
              </h1>
            </div>
          </Link>
          <a
            href="https://tinyurl.com/bokia-how-to"
            target="_blank"
            className="p-4 w-64 h-64"
            rel="noreferrer"
          >
            <div className="rounded-card">
              <img
                className="mx-auto w-24 h-24 object-cover"
                src={UserManual}
                alt="user_manual"
              />

              <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">
                User Manual
              </h1>
            </div>
          </a>
          <Link to="/change-password" className="p-4 w-64 h-64">
            <div className="rounded-card">
              <img
                className="mx-auto w-24 h-24 object-cover"
                src={PasswordIcon}
                alt="password"
              />

              <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">
                Change Password
              </h1>
            </div>
          </Link>

          <div
            className="p-4 w-64 h-64"
            onClick={async () => {
              await deleteUserService();
              localStorage.clear();
              window.location.replace('/');
            }}
          >
            <div className="rounded-card">
              <img
                className="mx-auto w-24 h-24 object-cover"
                src={DeleteIcon}
                alt="DeleteIcon"
              />

              <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">
                Delete Account
              </h1>
            </div>
          </div>

          <div
            className="p-4 w-64 h-64"
            onClick={() => {
              localStorage.clear();
              window.location.replace('/');
            }}
          >
            <div className="rounded-card">
              <img
                className="mx-auto w-24 h-24 object-cover"
                src={LogoutIcon}
                alt="logout"
              />

              <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">
                Logout
              </h1>
            </div>
          </div>

          
        </div>
      </section>
      <div className='sm:absolute left-0 bottom-0 right-0 bg-white'>
      <Footer />
      </div>
    </div>
  );
}
