import { useFormikContext } from "formik";

import Logo from "../assets/logo.png";

export default function Forgot() {
  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    isSubmitting,
  } = useFormikContext();

  return (
    <section className="w-screen h-screen bg-bg">
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mr-auto h-24 w-auto" src={Logo} alt="Workflow" />
            <div className="text-xl text-gray-600 mt-2 ">
              Forgot your password?
            </div>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="login-field"
                  placeholder="Email address"
                />
                {errors.email && (
                  <div className="text-xs mb-2 text-red-500">
                    {errors.email}
                  </div>
                )}
              </div>
            </div>

            <div>
              <button type="submit" className="btn-primary">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                Continue
                {isSubmitting && (
                  <svg
                    class="animate-spin ml-4 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
