/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import fileDownload from 'js-file-download'
import { BASE_URL } from '../../services/base.service'
import ButtonLoading from '../ButtonLoading'
import { CompanyContext } from '../../context/CompanyContext'
import { useContext } from 'react'

export default function InventoryReport({
  data,
  onSendEmail,
  loadingIndicator,
}) {
  const { company } = useContext(CompanyContext)
  const downloadFile = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }
  return (
    <div className="overflow-y-auto h-[510px]">
      <div className="font-semibold text-base">Inventory Report</div>
      {data?.products?.map((product) => (
        <div className="my-2 border-b py-2 px-2 text-black">
          <div className="flex justify-between">
            <span>Name</span>
            <span>{product.name}</span>
          </div>
          <div className="flex justify-between">
            <span>Supplier</span>
            <span>{product.supplier}</span>
          </div>
          <div className="flex justify-between">
            <span>Quantity</span>
            <span>{product.quantity}</span>
          </div>
          <div className="flex justify-between">
            <span>Restock Level</span>
            <span>{product.restock_level}</span>
          </div>
          <div className="flex justify-between">
            <span>Cost Price</span>
            <span>{product.cost_price}</span>
          </div>
          <div className="flex justify-between">
            <span>Updated At</span>
            <span>{product.updated_at}</span>
          </div>
        </div>
      ))}
      <div className="flex justify-between text-primary text-base font-semibold py-2 border-b">
        <span>Total Inventory Amount</span>

        <span>
          {company.currency_symbol || company.currency_symbol_code}{' '}
          {data?.data?.total_inventory?.toFixed(2) || 0}
        </span>
      </div>
      {data?.data && (
        <div className="flex items-center space-x-3 mt-4">
          <button onClick={onSendEmail} className=" btn-primary flex max-w-max">
            Send Email
            {loadingIndicator && <ButtonLoading />}
          </button>
          <a
            rel="noreferrer"
            className="btn-primary max-w-max"
            target="_blank"
            download
            style={{ cursor: 'pointer' }}
            onClick={() =>
              downloadFile(
                `${BASE_URL}/${data?.data?.download_link}`,
                'inventory_report'
              )
            }
          >
            Download Report
          </a>
        </div>
      )}
    </div>
  )
}
